/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatasourceJobParams } from './DatasourceJobParams';
import type { ExecutePreparedStatementJobAction } from './ExecutePreparedStatementJobAction';
import type { ExecuteStatementJobAction } from './ExecuteStatementJobAction';
import type { GetTablesJobAction } from './GetTablesJobAction';
import type { UserPasswordConnectionParams } from './UserPasswordConnectionParams';

export type JdbcDatasourceJobParams = (DatasourceJobParams & {
    action?: (ExecutePreparedStatementJobAction | ExecuteStatementJobAction | GetTablesJobAction);
    connectionParams?: UserPasswordConnectionParams;
    datasourceType?: JdbcDatasourceJobParams.datasourceType;
} & {
    action: (ExecutePreparedStatementJobAction | ExecuteStatementJobAction | GetTablesJobAction);
    connectionParams: UserPasswordConnectionParams;
    datasourceType: JdbcDatasourceJobParams.datasourceType;
});

export namespace JdbcDatasourceJobParams {

    export enum datasourceType {
        ORACLE = 'ORACLE',
        MYSQL = 'MYSQL',
        POSTGRES = 'POSTGRES',
    }


}
