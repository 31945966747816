import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { UserUiDto } from '@/api';
import { BackgroundColors } from '@/@types/types';

export default {
  path: 'domains',
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.domains',
  },
  component: MAIN_ROUTES.DOMAINS,
  children: [{
    path: '',
    name: 'domains',
    meta: {
      requiresAuth: true,
      hideBreadCrumb: true,
      breadCrumb: 'app.navigation-drawer.domains',
      roles: [UserUiDto.role.ADMIN],
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,

    },
    props: true,
    component: SUB_ROUTES.DOMAINS,
  }, {
    path: 'add',
    name: 'domains.domain.add',
    meta: {
      requiresAuth: true,
      breadCrumb: 'domains.addDomain',
      breadCrumbFromParamName: true,
      roles: [UserUiDto.role.ADMIN],
      background: BackgroundColors.SECONDARY,

    },
    props: true,
    component: SUB_ROUTES.DOMAINS_EDIT,
  }, {
    path: ':id',
    name: 'domains.domain.edit',
    meta: {
      requiresAuth: true,
      breadCrumb: 'domains.editDomain',
      roles: [UserUiDto.role.ADMIN],
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,

    },
    props: true,
    component: SUB_ROUTES.DOMAINS_EDIT,
  }],
};
