import Vue from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';
import store from '@/store';
import { APP, MAIN_ROUTES } from '@/router/components';
import dashboard from '@/router/dashboard';
import dataCatalog from '@/router/data-catalog';
import incidents from '@/router/incidents';
import integrations from '@/router/integrations';
import monitors from '@/router/monitors';
import settings from '@/router/settings';
import designSystem from '@/router/design-system';
import externaLinks from '@/external-links.json';
import log from '@/utils/console';
import i18n from '@/i18n';
import businessGlossary from './business-glossary';
import handleOAuthCallback from './oauth-callback';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = async function push(location: RawLocation) {
  let route: Route;
  try {
    route = await originalPush.call<VueRouter, [RawLocation], Promise<Route>>(this, location);
  } catch (err: any) {
    log('navigation error only visible in development', err);
  }
  return route!;
};

const routes = [
  {
    path: '/sign-in/saml2/test',
    name: 'TestSaml2',
    meta: {
      requiresAuth: false,
      breadCrumb: 'TestSSO',
    },
    component: MAIN_ROUTES.SAML2TEST,
  },
  {
    path: '/sign-in/:type?',
    name: 'SignIn',
    meta: {
      requiresAuth: false,
      breadCrumb: 'Sign In',
    },
    component: MAIN_ROUTES.SIGN_IN,
  },
  {
    path: '/support-sign-in',
    name: 'SupportSignIn',
    meta: {
      requiresAuth: false,
      breadCrumb: 'Support Sign In',
    },
    component: MAIN_ROUTES.SUPPORT_SIGN_IN,
  },
  {
    path: '/reset-password',
    name: 'ResetForgottenPassword',
    meta: {
      requiresAuth: false,
      breadCrumb: 'Reset Password',
    },
    component: MAIN_ROUTES.RESET_PASSWORD,
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    meta: {
      requiresAuth: false,
      breadCrumb: 'Create Password',
    },
    component: MAIN_ROUTES.RESET_PASSWORD,
  },
  {
    path: '/request-password-reset',
    name: 'RequestPasswordReset',
    meta: {
      requiresAuth: false,
      breadCrumb: 'Request Password Reset',
    },
    component: MAIN_ROUTES.REQUEST_PASSWORD_RESET,
  },
  {
    path: '/oauth/callback',
    name: 'OAuthCallback',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: async (to: Route, _from: any, next: () => void) => {
      await handleOAuthCallback(to);
      next();
    },
  },
  designSystem,
  {
    path: '/graph-test',
    name: 'GraphTest',
    meta: {
      requiresAuth: true,
      breadCrumb: 'Graph Test',
    },
    component: MAIN_ROUTES.GRAPH_TEST,
  },
  {
    path: '',
    redirect: {
      name: 'dashboard',
    },
    meta: {
      requiresAuth: true,
      breadCrumb: 'Home',
      hideBreadCrumb: true,
    },
    component: APP,
    children: [
      dashboard,
      dataCatalog,
      businessGlossary,
      incidents,
      integrations,
      monitors,
      settings,
      {
        path: '/help',
        name: 'help',
        beforeEnter() {
          window.open(externaLinks.documentation_link, '_blank');
        },
      },
    ],
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (from.path === to.path) {
      // Prevent scroll to top when navigating to the same route
      // This is mainly used for filters change
      return undefined;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const authenticated = store.getters['auth/getUser'];
  const role = store.getters['auth/getUserRole'];

  // test if route requires auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // test if user is authenticated
    if (!authenticated) { // if not, redirect to login page.
      // if user is not authenticated, but is trying to access a specific route, save it
      if (to.name) {
        store.commit('auth/setTemporaryRoute', to.fullPath);
      }
      next({
        name: 'SignIn',
      });
      // test if user is authorized
    } else if (to?.meta?.roles && !to?.meta?.roles.includes(role)) { // if not, redirect to dashboard.
      next({
        name: 'dashboard',
      });
    // test if user is authorized
    } else {
      next(); // if authenticated, proceed.
    }
  // test if route is public
  } else {
    next(); // if public, proceed.
  }
});

router.afterEach((to) => {
  if (to?.meta?.breadCrumb) {
    document.title = i18n.t(`${to.meta.breadCrumb}`);
  }
});

export default router;
