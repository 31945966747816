import i18n from '@/i18n';

const isRequired = (v: number | null | string) => (v != null && v !== '') || i18n.t('siffletify_components.stextfields.rules.required');

const isRequiredMultiple = (v: string[] | null) => (v != null && v.length > 0) || i18n.t('siffletify_components.stextfields.rules.required');

const isInteger = (v: number | null | string) => {
  if (v == null) return true;
  return Number.isInteger(v) || i18n.t('siffletify_components.stextfields.rules.integer');
};

const minValue = (n: number) => (v: number): boolean | string => {
  if (v == null) return true;
  return v >= n || i18n.t('siffletify_components.stextfields.rules.min', { n });
};

const maxValue = (n: number) => (v: number) => {
  if (v == null) return true;
  return v <= n || i18n.t('siffletify_components.stextfields.rules.max', { n });
};

const minLowerThanMaxRule = (n: number | null) => (v: number) => {
  if (v == null || n == null) return true;
  return v <= n || i18n.t('siffletify_components.stextfields.rules.must_be_smaller_than', { n });
};

export {
 isRequired, isRequiredMultiple, isInteger, minValue, maxValue, minLowerThanMaxRule,
};
