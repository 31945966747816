/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetCustomMetadataListResponseDto = {
    id: string;
    name: string;
    type: GetCustomMetadataListResponseDto.type;
};

export namespace GetCustomMetadataListResponseDto {

    export enum type {
        LABEL = 'LABEL',
        USER = 'USER',
        STRING = 'STRING',
    }


}
