/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PublicUserPermissionAssignmentDto } from './PublicUserPermissionAssignmentDto';

export type PublicUserGetDto = {
    authTypes: Array<'SAML2' | 'LOGIN_PASSWORD'>;
    email: string;
    id: string;
    name: string;
    permissions: Array<PublicUserPermissionAssignmentDto>;
    role: PublicUserGetDto.role;
};

export namespace PublicUserGetDto {

    export enum role {
        ADMIN = 'ADMIN',
        EDITOR = 'EDITOR',
        VIEWER = 'VIEWER',
    }


}
