/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information about the last run of the source
 */
export type PublicGetLastRunV2Dto = {
    /**
     * Last run status of the source
     */
    status?: PublicGetLastRunV2Dto.status;
    /**
     * Timestamp of the last update of the source
     */
    timestamp?: string;
};

export namespace PublicGetLastRunV2Dto {

    /**
     * Last run status of the source
     */
    export enum status {
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
    }


}
