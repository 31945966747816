import { render, staticRenderFns } from "./Navigation-Drawer-Item.vue?vue&type=template&id=479bc6c2&lang=pug"
import script from "./Navigation-Drawer-Item.vue?vue&type=script&setup=true&lang=ts"
export * from "./Navigation-Drawer-Item.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Navigation-Drawer-Item.vue?vue&type=style&index=0&id=479bc6c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports