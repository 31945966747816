import { render, staticRenderFns } from "./Users-Results-Card.vue?vue&type=template&id=1966789c&lang=pug"
import script from "./Users-Results-Card.vue?vue&type=script&lang=ts&setup=true"
export * from "./Users-Results-Card.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports