import { Route } from 'vue-router';
import { type OAuthIntegrationRedirectDto } from '@/api';

const handleOAuthCallback = async (to: Route) => {
  const provider = to.query.provider as OAuthIntegrationRedirectDto.provider | null;
  const state = to.query.state as string | null;
  const code = to.query.code as string | null;

  if (provider && state && code) {
    // Send message with received info to the parent window
    window.opener?.postMessage(
      {
        type: 'oauth-callback-status',
        result: 'SUCCESS',
        payload: { code, state, provider },
      },
      window.location.origin,
    );
  } else {
    // Send failure message to the parent window
    window.opener?.postMessage(
      {
        type: 'oauth-callback-status',
        result: 'FAILURE',
        errorMessage: 'Missing parameters from API response',
      },
      window.location.origin,
    );
  }
  // Close the popup window
  window.close();
};

export default handleOAuthCallback;
