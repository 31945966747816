<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 * @see https://www.figma.com/design/Sp1RzeCNxihSU4TZaLGlqT/Components?node-id=385-1876&m=dev
 */
import SIcon from '@/components/SIcon.vue';

export type SiffletBannerType = 'info' | 'warning' | 'error' | 'success' | 'ai';

export interface SBannerProps {
  /**
   * Type of the message bar.
   */
  type?: SiffletBannerType,
  /**
   * Title of the message bar.
   */
  title: string,
  /**
   * Subtitle of the message bar.
   */
  subtitle?: string,
  /**
   * Whether the message bar is dismissible.
   */
  dismissible?: boolean,
}

const props = defineProps({
  type: { default: 'info' },
  title: null,
  subtitle: null,
  dismissible: { type: Boolean, default: false }
});

const emit = defineEmits(["dismiss"]);

const icon = _computed(() => {
  switch (props.type) {
    case 'info':
      return 'icon-info-circle-fill';
    default:
      return 'icon-info-circle-fill';
  }
});

const color = _computed(() => {
  switch (props.type) {
    case 'info':
      return 'bgPageSecondary';
    case 'warning':
      return 'bgWarningPrimary';
    case 'error':
      return 'bgDangerPrimary';
    case 'success':
      return 'bgSuccessPrimary';
    case 'ai':
      return 'iconAi';
    default:
      return 'bgPageTertiary';
  }
});

const iconColor = _computed(() => {
  switch (props.type) {
    case 'info':
      return 'textSecondary';
    case 'warning':
      return 'iconWarning';
    case 'error':
      return 'iconDanger';
    case 'success':
      return 'iconSuccess';
    default:
      return 'iconAi';
  }
});

const close = (callback: () => void) => {
  emit('dismiss');
  callback();
};

</script>

<template lang="pug">
v-alert.mb-0.py-2(
  :color="color"
  :icon="icon"
  :colored-border="false"
  :class="`banner banner-${props.type}`"
  tile
  :dismissible="dismissible")

  template( #prepend )
    SIcon.align-self-start.mr-2( #prepend :icon="icon" :color="iconColor" )

  template( #default )
    .d-flex.flex-column
      .textPrimary--text {{ title }}
      .caption.textPrimary--text( v-if="subtitle" ) {{ subtitle }}

  template( v-slot:close="{ toggle }" v-if="dismissible" )
    SIcon.align-self-start.ml-auto( icon="icon-dismiss-medium" color="textPrimary" @click.native="close(toggle)" )

</template>

<style lang="scss" scoped>
.banner {
  border-bottom: 1px solid;
  &-info {
    border-color: var(--v-borderPrimary-base) !important;
  }
  &-warning {
    border-color: var(--v-borderWarning-base) !important;
  }
  &-error {
    border-color: var(--v-borderDanger-base) !important;
  }
  &-success {
    border-color: var(--v-borderSuccess-base) !important;
  }
  &-ai {
    background: linear-gradient(90deg, map-get($purple, 'lighten-5'), map-get($purple, 'lighten-4'));
    border-color: var(--v-borderAi-base) !important;
  }
}
</style>
