/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TransformationRunDto = {
    durationMillis?: number;
    endTime?: number;
    estimatedCostCents?: number;
    logs?: string;
    startTime?: number;
    status?: TransformationRunDto.status;
};

export namespace TransformationRunDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
        SKIPPED = 'SKIPPED',
    }


}
