/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PatchIncidentDto = {
    assignedUserIds?: Array<string>;
    collaborationToolItemIds?: Array<string>;
    criticality?: number;
    name?: string;
    qualification?: PatchIncidentDto.qualification;
    status?: PatchIncidentDto.status;
};

export namespace PatchIncidentDto {

    export enum qualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        REVIEWED = 'REVIEWED',
        DUPLICATE = 'DUPLICATE',
        AUTOMATIC = 'AUTOMATIC',
        QUALIFIED_MONITORS_REVIEWED = 'QUALIFIED_MONITORS_REVIEWED',
        QUALIFIED_MONITORS_NO_ACTION_NEEDED = 'QUALIFIED_MONITORS_NO_ACTION_NEEDED',
        QUALIFIED_MONITORS_FALSE_POSITIVE = 'QUALIFIED_MONITORS_FALSE_POSITIVE',
    }

    export enum status {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
