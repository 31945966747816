/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PublicUserPermissionAssignmentDto } from './PublicUserPermissionAssignmentDto';

export type PublicUserUpdateDto = {
    authTypes?: Array<'SAML2' | 'LOGIN_PASSWORD'>;
    name: string;
    permissions: Array<PublicUserPermissionAssignmentDto>;
    role: PublicUserUpdateDto.role;
};

export namespace PublicUserUpdateDto {

    export enum role {
        ADMIN = 'ADMIN',
        EDITOR = 'EDITOR',
        VIEWER = 'VIEWER',
    }


}
