<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import SModal from '@/components/SModal.vue';
import {
 RuleCatalogAssetDtoV2, RuleService,
} from '@/api';
import { type MonitorSchema } from '@/modules/monitors/monitor-wizard/monitor-wizard-schema';
import SBanner from '@/components/SBanner/SBanner.vue';
import ExcludedDates from '@/modules/monitors/monitor-wizard/common/ExcludedDates/ExcludedDates.vue';
import AmountInput from '@/modules/monitors/monitor-wizard/common/AmountInput/AmountInput.vue';
import LookBackPeriodUnit from '@/modules/monitors/monitor-wizard/common/LookBackPeriodUnit/LookBackPeriodUnit.vue';
import SLabel from '@/components/SLabel.vue';
import { type TimeSettings } from '@/modules/monitors/monitor-wizard/TimeSettings.vue';
import { type DeepNullable } from '@/@types/types';
import TitleAndSubtitle from '@/components/TitleAndSubtitle/TitleAndSubtitle.vue';
import getSettingsFromMonitorType from '../../monitor-wizard/monitor-configurations/monitor-configurations';

type MonitorsEditEmits = {
  (event: 'update'): void
}

const modalDialogRef = _ref<InstanceType<typeof SModal> | null>(null);
let monitors = _ref<RuleCatalogAssetDtoV2[]>([]);

const tempTimeSettings = _ref<DeepNullable<TimeSettings>>({
    excludedDates: null,
    offsetPeriods: null,
    frequencyType: null,
    deltaQueryingValue: null,
});

const valid = _ref(false);

const open = () => modalDialogRef.value!.openModal();
const close = () => modalDialogRef.value!.closeModal();

const emit = defineEmits(["update"]);

const monitorsCount = _computed(() => monitors.value.length);

const monitorsThatHaveTimeSettings = _computed(() => monitors.value.filter((monitor) => monitor.ruleParams?.hasTimeWindow));

const supportedMonitors = _computed(() => monitors.value
.filter((monitor) => getSettingsFromMonitorType(monitor.ruleTemplateName as MonitorSchema['ruleTemplateName'])?.hasTimeSettings)
.filter((monitor) => monitor.ruleParams?.hasTimeWindow));

const hasSupportedMonitors = _computed(() => supportedMonitors.value.length > 0);

const single = _computed(() => supportedMonitors.value.length === 1);

// Show warning message if no monitors support time settings
const showWarningMessage = _computed(() => supportedMonitors.value.length === 0);

// Show info message only if showWarningMessage is false and monitorsThatHaveTimeSettings is a subset of monitors
const showInfoMessage = _computed(() => !showWarningMessage.value && monitorsThatHaveTimeSettings.value.length < monitors.value.length);

const timeSettingsPatch = _computed(() => {
  // if excludedDates is empty array then set it to null
  if (tempTimeSettings.value.excludedDates?.length === 0) {
    tempTimeSettings.value.excludedDates = null;
  }
  return Object.fromEntries(Object.entries(tempTimeSettings.value).filter(([_, value]) => value !== null));
});

const canSave = _computed(() => Object.keys(timeSettingsPatch.value).length > 0 && hasSupportedMonitors.value);

const init = () => {
  monitors.value = [];
  tempTimeSettings.value.excludedDates = null;
  tempTimeSettings.value.offsetPeriods = null;
  tempTimeSettings.value.frequencyType = null;
  tempTimeSettings.value.deltaQueryingValue = null;
};

const setMonitors = async (newMonitors: RuleCatalogAssetDtoV2[]) => {
  init();
  monitors.value = newMonitors;
  if (single.value) {
    const { id } = supportedMonitors.value[0];
    if (!id) return;
    const monitor = await RuleService.getSiffletRuleById({ id });
    tempTimeSettings.value.excludedDates = monitor.inputValues?.excludedDates ?? null;
    tempTimeSettings.value.offsetPeriods = monitor.inputValues?.offsetPeriods ?? null;
    tempTimeSettings.value.frequencyType = monitor.inputValues?.frequencyType ?? null;
    tempTimeSettings.value.deltaQueryingValue = monitor.inputValues?.deltaQueryingValue ?? null;
  }
  open();
};

const update = async () => {
  await Promise.all(supportedMonitors.value.map((monitor) => RuleService.patchRule({
      id: monitor.id,
      requestBody: {
        ruleParamsInputValues: {
          ...monitor.ruleParams,
          ...timeSettingsPatch.value,
        },
      },
    })));
  close();
  emit('update');
};

defineExpose({ setMonitors });
</script>

<template lang="pug">
SModal(
  ref="modalDialogRef"
  :title="$tc('monitors.edit_settings_dialog_title', monitorsCount)"
  :can-save="canSave"
  :on-save="update"
  :on-close="close"
  :can-cancel="true"
  :has-action="true"
  width="800"
  :has-secondary-background="false"
  remove-dividers
  data-cy="monitors-edit-modal" )

  // Warning banner, displayed if no selected monitor supports time settings
  SBanner.mx-n6.mt-n2.mb-4(
    v-if="showWarningMessage"
    type="warning"
    :title="$tc('monitors.edit_settings_dialog_warning_title', monitorsCount)"
  )

  // Info banner, displayed if some selected monitors don't support time settings or have no time settings
  SBanner.mx-n6.mt-n2.mb-4(
    v-if="showInfoMessage"
    type="info"
    :title="$t('monitors.edit_settings_dialog_info_title')"
    :subtitle="$tc('monitors.edit_settings_dialog_info_subtitle', supportedMonitors.length)"
  )

  v-form( v-model="valid" lazy-validation )

    ExcludedDates( :disabled="!hasSupportedMonitors" v-model="tempTimeSettings.excludedDates" )

    v-divider.my-6

    TitleAndSubtitle.mb-6(
      :title="$t('monitor_wizard.time_settings.time_window_offset_label')"
      :subtitle="$t('monitor_wizard.time_settings.time_window_offset_description')"
    )

    SLabel( as-columns ) {{ $t('monitor_wizard.time_settings.time_offset_amount_label') }}
      v-row( slot="input" no-gutters)
        v-col
          AmountInput.mr-2( :disabled="!hasSupportedMonitors" not-required v-model.number="tempTimeSettings.offsetPeriods" )
        v-col
          LookBackPeriodUnit( disabled :value="tempTimeSettings.frequencyType" )

    v-divider.my-6

    TitleAndSubtitle.mb-6(
      :title="$t('monitor_wizard.time_settings.look_back_period_label')"
      :subtitle="$t('monitor_wizard.time_settings.look_back_period_description')"
    )

    SLabel( as-columns )
      span( style="letter-spacing: -0.03rem !important;" ) {{ $t('monitor_wizard.time_settings.look_back_period_amount_label') }}
      v-row( slot="input" no-gutters)
        v-col
          AmountInput.mr-2( :disabled="!hasSupportedMonitors" not-required v-model.number="tempTimeSettings.deltaQueryingValue" )
        v-col
          LookBackPeriodUnit( disabled :value="tempTimeSettings.frequencyType" )
</template>
