<script lang="ts" setup>
import { computed } from 'vue';
import { DataItemProps } from 'vuetify';
import { TagDto } from '@/api';
import copyToClipboard from '@/utils/copyToClipboard';
import i18n from '@/i18n';

interface TagsResultCardProps {
  itemProps: Omit<DataItemProps, 'item'> & { item: TagDto };
  canEdit?: boolean;
  canDelete?: boolean;
}

type TagsResultCardEmits = {
  (event: 'edit', item: TagDto): void
  (event: 'delete', item: TagDto): void
}

const props = defineProps({
  itemProps: null,
  canEdit: { type: Boolean, default: false },
  canDelete: { type: Boolean, default: false }
});
const emit = defineEmits(["edit", "delete"]);

const isClassification = computed(() => props.itemProps.item.type === TagDto.type.VISIBLE_DATA_CLASSIFICATION);

const edit = () => {
  emit('edit', props.itemProps.item);
};
const deleteItem = () => {
  emit('delete', props.itemProps.item);
};
const copyIDToClipboard = () => {
  copyToClipboard(props.itemProps.item.id, `${i18n.t('tags.copy_id_success', { tag: props.itemProps.item.name })}`);
};
</script>

<template lang="pug">
SThreeDotMenu(
  data-cy="users-result-card-menu-button"
  variant="text")
  SMenuButton(
    v-if="!isClassification && canEdit"
    @click="edit"
    data-cy="users-result-card-menu-edit"
    icon="icon-edit"
    :text="$t('tags.edit')")
  SMenuButton(
    @click="copyIDToClipboard"
    icon="icon-copy"
    :text="$t('tags.copy_id')")
  SMenuButton(
    v-if="!isClassification && canDelete"
    @click="deleteItem"
    data-cy="users-result-card-menu-delete"
    icon="$delete"
    color="danger"
    :text="$t('tags.delete')")
</template>
