/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RequestOAuthIntegrationConfigDto = {
    clientId: string;
    clientSecret?: string;
    provider: RequestOAuthIntegrationConfigDto.provider;
    url: string;
};

export namespace RequestOAuthIntegrationConfigDto {

    export enum provider {
        SERVICENOW = 'SERVICENOW',
    }


}
