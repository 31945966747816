/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DebugJobAction = {
    type: DebugJobAction.type;
};

export namespace DebugJobAction {

    export enum type {
        TEST_CONNECTION = 'TEST_CONNECTION',
    }


}
