<script setup lang="ts">import { computed as _computed } from 'vue';

interface SLabelProps {
  asContainer?: boolean;
  info?: string;
  required?: boolean;
  isBeta?: boolean;
  labelClasses?: string;
  asColumns?: boolean;
  labelMaxWidth?: string;
}

const props = defineProps({
  asContainer: { type: Boolean, default: false },
  info: { default: '' },
  required: { type: Boolean, default: false },
  isBeta: { type: Boolean, default: false },
  labelClasses: { default: '' },
  asColumns: { type: Boolean, default: false },
  labelMaxWidth: { default: '' }
});

const tag = props.asContainer ? 'div' : 'label';

const maxWidthStyle = _computed(() => (props.labelMaxWidth ? `max-width: ${props.labelMaxWidth}` : ''));
</script>

<template lang="pug">
// eslint-disable-next-line vuejs-accessibility/label-has-for
component.label.textPrimary--text( :is="tag" v-bind="$attrs" :class="{ [`d-block`]: asColumns }" @click.prevent )
  v-row(:class="labelClasses" no-gutters)
    v-col( :cols="asColumns ? null : 12" :class="{ [`pt-2`]: asColumns }" :style="maxWidthStyle" )
      span.font-weight-medium.body-2
        slot
      v-chip.ml-1.text-uppercase.font-weight-bold(v-if="isBeta" color="secondary" x-small outlined) {{ $t('assets.beta') }}
      span.ml-1.red--text.text--darken-1(v-if="required") {{ $t('common.misc.asterisk') }}
      v-tooltip( top v-if="info" max-width="300" )
        template( v-slot:activator="{ on }" )
          v-icon.ml-1.mt-n1( v-on="on" size="medium" ) icon-info-circle-outline
        span {{ info }}

    v-col( :cols="asColumns ? 9 : 12")
      slot( name="input" )
</template>
