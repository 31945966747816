/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JoinConditionDto = {
    kind: JoinConditionDto.kind;
};

export namespace JoinConditionDto {

    export enum kind {
        EQUALITY = 'Equality',
    }


}
