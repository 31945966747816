/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Tags of the asset
 */
export type PublicTagReferenceGetDto = {
    /**
     * Id of the referenced object
     */
    id?: string;
    /**
     * Type of the referenced tag
     */
    kind?: PublicTagReferenceGetDto.kind;
    /**
     * Name of the referenced object
     */
    name?: string;
};

export namespace PublicTagReferenceGetDto {

    /**
     * Type of the referenced tag
     */
    export enum kind {
        TAG = 'TAG',
        CLASSIFICATION = 'CLASSIFICATION',
        BIGQUERY_EXTERNAL = 'BIGQUERY_EXTERNAL',
        SNOWFLAKE_EXTERNAL = 'SNOWFLAKE_EXTERNAL',
        DBT_EXTERNAL = 'DBT_EXTERNAL',
    }


}
