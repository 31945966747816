/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EqualityJoinConditionDto } from './EqualityJoinConditionDto';

export type JoinStepDto = {
    datasetId: string;
    joinCondition: EqualityJoinConditionDto;
    joinType: JoinStepDto.joinType;
};

export namespace JoinStepDto {

    export enum joinType {
        INNER = 'Inner',
        LEFT = 'Left',
        RIGHT = 'Right',
        OUTER = 'Outer',
    }


}
