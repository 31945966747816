<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

// ToastUI Library : https://nhn.github.io/tui.editor/latest/ToastUIEditorCore
// https://github.com/nhn/tui.editor/blob/master/docs
// TUI Vue Wrapper : https://github.com/nhn/tui.editor/tree/master/apps/vue-editor
// eslint-disable-next-line import/no-extraneous-dependencies
import '@toast-ui/editor/dist/toastui-editor.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@toast-ui/editor/dist/i18n/fr-fr';
import { Editor } from '@toast-ui/vue-editor';
import { onMounted, watch } from 'vue';
import { getModule } from 'vuex-module-decorators';
import uiModule from '@/store/modules/ui';
import store from '@/store';

interface SRichTextEditorProps {
  value?: string // v-model binding prop
  height?: string
  minHeight?: string
  disableShortcuts?: boolean
  onBlur?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
  onKeydown?: () => void;
  onKeyup?: () => void;
  onLoad?: () => void;
}

type SRichTextEditorEmits = {
  (event: 'input', value: string): void // v-model binding event
  (event: 'editorBlur'): void
  (event: 'editorChanged', value: string): void
  (event: 'editorFocus'): void
  (event: 'editorKeydown'): void
  (event: 'editorKeyup'): void
  (event: 'editorLoaded'): void
}

const DEFAULT_TOOLBAR_ITEMS = [
  ['heading'],
  ['bold', 'italic', 'strike'],
  ['ul', 'ol'],
  ['quote', 'code', 'codeblock'],
  ['image', 'link', 'table'],
];

/* const ICON_OVERRIDES = {
  heading: 'icon-text-size',
  bold: 'icon-text-bold',
  italic: 'icon-text-italic',
  strike: 'icon-text-strikethrough',
  'bullet-list': 'icon-list-bullet',
  'ordered-list': 'icon-list-number',
  image: 'icon-image',
  link: 'icon-link',
  table: 'icon-data-table',
  quote: 'icon-add',
  code: 'icon-code-brackets',
  codeblock: 'icon-dismiss',
}; */

const props = defineProps({
  value: null,
  height: { default: 'auto' },
  minHeight: { default: '200px' },
  disableShortcuts: { type: Boolean, default: false },
  onBlur: { type: Function },
  onChange: { type: Function },
  onFocus: { type: Function },
  onKeydown: { type: Function },
  onKeyup: { type: Function },
  onLoad: { type: Function }
});

const emit = defineEmits(["input", "editorBlur", "editorChanged", "editorFocus", "editorKeydown", "editorKeyup", "editorLoaded"]);

const editor = _ref<Editor>();
const ui = getModule(uiModule, store);

const editorConfig = _computed(() => ({
  language: ui.language,
  useCommandShortcut: !props.disableShortcuts,
  usageStatistics: false,
  hideModeSwitch: true,
  toolbarItems: DEFAULT_TOOLBAR_ITEMS,
}));

const getHTMLValue = () => editor.value?.invoke('getHTML') || '';
const getMarkdownValue = () => editor.value?.invoke('getMarkdown') || '';
const setHTMLValue = (value: string) => editor.value?.invoke('setHTML', value);

const handleEditorLoad = () => {
  emit('editorLoaded');
  props.onLoad?.();
};

const handleEditorChange = () => {
  emit('input', getHTMLValue());
  // Event below preserved for backward compatibility
  emit('editorChanged', getHTMLValue());
  props.onChange?.();
};

const handleEditorFocus = () => {
  emit('editorFocus');
  props.onFocus?.();
};

const handleEditorBlur = () => {
  emit('editorBlur');
  props.onBlur?.();
};

const handleEditorKeyup = () => {
  emit('editorKeyup');
  props.onKeyup?.();
};

const handleEditorKeydown = () => {
  emit('editorKeydown');
  props.onKeydown?.();
};

watch(() => props.value, (newValue) => {
  if (newValue !== undefined && newValue !== getHTMLValue()) {
    setHTMLValue(newValue);
  }
});

defineExpose({ getHTMLValue, getMarkdownValue, setHTMLValue });

onMounted(() => {
  if (props.value) setHTMLValue(props.value);
});
</script>

<template lang="pug">
Editor.TuiEditor(
  ref="editor"
  initialEditType="wysiwyg"
  :min-height="minHeight"
  :height="height"
  :options="editorConfig"
  @blur="handleEditorBlur"
  @change="handleEditorChange"
  @focus="handleEditorFocus"
  @keyup="handleEditorKeyup"
  @keydown="handleEditorKeydown"
  @load="handleEditorLoad"
)
</template>
