/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetCollaborationToolConfigResponseDto = {
    type: GetCollaborationToolConfigResponseDto.type;
};

export namespace GetCollaborationToolConfigResponseDto {

    export enum type {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
