<script lang="ts" setup>import { computed as _computed } from 'vue';

import { DataItemProps } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { UserUiDto } from '@/api';
import store from '@/store';
import usersModule from '@/store/modules/users';
import copyToClipboard from '@/utils/copyToClipboard';
import i18n from '@/i18n';

const users = getModule(usersModule, store);

interface UsersResultsCardProps {
  itemProps: Omit<DataItemProps, 'item'> & { item: UserUiDto };
}

const props = defineProps({
  itemProps: null
});

const isActive = _computed(() => props.itemProps.item.isActive);

const edit = () => {
  users.editSingleUser(props.itemProps.item.id);
};
const resetPassword = () => {
  users.resetPassword(props.itemProps.item);
};
const disable = () => {
  users.disableSingleUser(props.itemProps.item);
};
const enable = () => {
  users.enableUser(props.itemProps.item);
};
const deleteItem = () => {
  users.deleteSingleUser(props.itemProps.item);
};
const copyIDToClipboard = () => {
  copyToClipboard(props.itemProps.item.id, `${i18n.t('users.copy_id_success', { name: props.itemProps.item.name })}`);
};
</script>

<template lang="pug">
SThreeDotMenu(data-cy="users-result-card-menu-button" variant="text")
  SMenuButton(
    @click="copyIDToClipboard"
    data-cy="users-result-card-menu-copy-id"
    icon="icon-copy"
    :text="$t('users.copy_id')")
  SMenuButton(
    v-if="props.itemProps.item.authTypes.includes('LOGIN_PASSWORD')"
    @click="resetPassword"
    data-cy="users-result-card-menu-reset-password"
    icon="icon-password-edit"
    :text="$t('users.reset_password')")
  SMenuButton(
    @click="edit"
    data-cy="users-result-card-menu-edit"
    icon="icon-edit"
    :text="$t('users.edit')")
  SMenuButton(
    v-if="isActive"
    @click="disable"
    data-cy="users-result-card-menu-disable"
    icon="icon-dismiss-circle-outline"
    :text="$t('users.disable')")
  SMenuButton(
    v-if="!isActive"
    @click="enable"
    data-cy="users-result-card-menu-enable"
    icon="icon-check-circle-outline"
    :text="$t('users.enable')")
  SMenuButton(
    @click="deleteItem"
    data-cy="users-result-card-menu-delete"
    icon="$delete"
    color="danger"
    :text="$t('users.delete')")
</template>
