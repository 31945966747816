<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 *
 * Display the creation method as a SChipBasic tag
 *
 */
import { RuleDto } from '@/api';
import SChipBasic from '@/components/SChipBasic.vue';
import { type IconSize } from '@/components/SIcon.vue';

interface CreationMethodProps {
  method: RuleDto.creationMethod;
  size?: IconSize;
}
const props = defineProps({
  method: null,
  size: { default: 'default' }
});

const icon = _computed(() => {
  switch (props.method) {
    case RuleDto.creationMethod.UI:
      return 'icon-cursor-arrow';
    case RuleDto.creationMethod.DBT_INGESTION:
      return 'icon-monitor-square';
    case RuleDto.creationMethod.AS_CODE:
      return 'icon-code-brackets';
    case RuleDto.creationMethod.AUTO_COVERAGE:
      return 'icon-monitor-square-auto-coverage';
    default:
      return '';
  }
});

</script>

<template lang="pug">
SChipBasic( v-if="method" :text="$t(`tree_selector.creation_method.${method}`)" :icon="icon" :size="size" )
</template>
