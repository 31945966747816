/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeAlertingHookNotificationDto } from './AsCodeAlertingHookNotificationDto';
import type { AsCodeCompletenessMonitorParamsDto } from './AsCodeCompletenessMonitorParamsDto';
import type { AsCodeConnectionParamsOverrideDto } from './AsCodeConnectionParamsOverrideDto';
import type { AsCodeCustomMetricsMonitorParamsDtoV1 } from './AsCodeCustomMetricsMonitorParamsDtoV1';
import type { AsCodeCustomMetricsMonitorParamsDtoV2 } from './AsCodeCustomMetricsMonitorParamsDtoV2';
import type { AsCodeDatasetReferenceDto } from './AsCodeDatasetReferenceDto';
import type { AsCodeDistributionMonitorParamsDto } from './AsCodeDistributionMonitorParamsDto';
import type { AsCodeDuplicatesMonitorParamsDto } from './AsCodeDuplicatesMonitorParamsDto';
import type { AsCodeDynamicFieldProfilingMonitorParamsDto } from './AsCodeDynamicFieldProfilingMonitorParamsDto';
import type { AsCodeDynamicMetricMonitorParamsDto } from './AsCodeDynamicMetricMonitorParamsDto';
import type { AsCodeFieldDuplicatesMonitorParamsDto } from './AsCodeFieldDuplicatesMonitorParamsDto';
import type { AsCodeFieldFormatMonitorParamsDtoV1 } from './AsCodeFieldFormatMonitorParamsDtoV1';
import type { AsCodeFieldFormatMonitorParamsDtoV2 } from './AsCodeFieldFormatMonitorParamsDtoV2';
import type { AsCodeFieldInListConstraintMonitorParamsDtoV1 } from './AsCodeFieldInListConstraintMonitorParamsDtoV1';
import type { AsCodeFieldInListConstraintMonitorParamsDtoV2 } from './AsCodeFieldInListConstraintMonitorParamsDtoV2';
import type { AsCodeFieldNullsMonitorParamsDto } from './AsCodeFieldNullsMonitorParamsDto';
import type { AsCodeFieldUniquenessMonitorParamsDto } from './AsCodeFieldUniquenessMonitorParamsDto';
import type { AsCodeFreshnessMonitorParamsDtoV1 } from './AsCodeFreshnessMonitorParamsDtoV1';
import type { AsCodeFreshnessMonitorParamsDtoV2 } from './AsCodeFreshnessMonitorParamsDtoV2';
import type { AsCodeIncidentDto } from './AsCodeIncidentDto';
import type { AsCodeJiraNotificationDto } from './AsCodeJiraNotificationDto';
import type { AsCodeJoinDto } from './AsCodeJoinDto';
import type { AsCodeMetadataFreshnessMonitorParamsDtoV1 } from './AsCodeMetadataFreshnessMonitorParamsDtoV1';
import type { AsCodeMetadataFreshnessMonitorParamsDtoV2 } from './AsCodeMetadataFreshnessMonitorParamsDtoV2';
import type { AsCodeMetricsMonitorParamsDto } from './AsCodeMetricsMonitorParamsDto';
import type { AsCodeReferenceByIdOrNameDtoImpl } from './AsCodeReferenceByIdOrNameDtoImpl';
import type { AsCodeReferentialIntegrityMonitorParamsDtoV1 } from './AsCodeReferentialIntegrityMonitorParamsDtoV1';
import type { AsCodeReferentialIntegrityMonitorParamsDtoV2 } from './AsCodeReferentialIntegrityMonitorParamsDtoV2';
import type { AsCodeRowDuplicatesMonitorParamsDto } from './AsCodeRowDuplicatesMonitorParamsDto';
import type { AsCodeSchemaChangeMonitorParamsDto } from './AsCodeSchemaChangeMonitorParamsDto';
import type { AsCodeServiceNowNotificationDto } from './AsCodeServiceNowNotificationDto';
import type { AsCodeSqlMonitorParamsDto } from './AsCodeSqlMonitorParamsDto';
import type { AsCodeStaticCompletenessMonitorParamsDto } from './AsCodeStaticCompletenessMonitorParamsDto';
import type { AsCodeStaticFieldProfilingMonitorParamsDto } from './AsCodeStaticFieldProfilingMonitorParamsDto';
import type { AsCodeStaticMetricMonitorParamsDto } from './AsCodeStaticMetricMonitorParamsDto';
import type { AsCodeTagReferenceDto } from './AsCodeTagReferenceDto';
import type { AsCodeValueRangeMonitorParamsDto } from './AsCodeValueRangeMonitorParamsDto';
import type { AsCodeVolumeMonitorParamsDto } from './AsCodeVolumeMonitorParamsDto';

export type AsCodeMonitorDto = {
    connection?: AsCodeConnectionParamsOverrideDto;
    datasets?: Array<AsCodeDatasetReferenceDto>;
    description?: string;
    friendlyId?: string;
    id?: string;
    incident: AsCodeIncidentDto;
    joins: Array<AsCodeJoinDto>;
    kind?: AsCodeMonitorDto.kind;
    name: string;
    notifications?: Array<(AsCodeAlertingHookNotificationDto | AsCodeJiraNotificationDto | AsCodeServiceNowNotificationDto)>;
    parameters: (AsCodeCompletenessMonitorParamsDto | AsCodeCustomMetricsMonitorParamsDtoV1 | AsCodeCustomMetricsMonitorParamsDtoV2 | AsCodeDistributionMonitorParamsDto | AsCodeDuplicatesMonitorParamsDto | AsCodeDynamicFieldProfilingMonitorParamsDto | AsCodeDynamicMetricMonitorParamsDto | AsCodeFieldDuplicatesMonitorParamsDto | AsCodeFieldFormatMonitorParamsDtoV1 | AsCodeFieldFormatMonitorParamsDtoV2 | AsCodeFieldInListConstraintMonitorParamsDtoV1 | AsCodeFieldInListConstraintMonitorParamsDtoV2 | AsCodeFieldNullsMonitorParamsDto | AsCodeFieldUniquenessMonitorParamsDto | AsCodeFreshnessMonitorParamsDtoV1 | AsCodeFreshnessMonitorParamsDtoV2 | AsCodeMetadataFreshnessMonitorParamsDtoV1 | AsCodeMetadataFreshnessMonitorParamsDtoV2 | AsCodeMetricsMonitorParamsDto | AsCodeReferentialIntegrityMonitorParamsDtoV1 | AsCodeReferentialIntegrityMonitorParamsDtoV2 | AsCodeRowDuplicatesMonitorParamsDto | AsCodeSchemaChangeMonitorParamsDto | AsCodeSqlMonitorParamsDto | AsCodeStaticCompletenessMonitorParamsDto | AsCodeStaticFieldProfilingMonitorParamsDto | AsCodeStaticMetricMonitorParamsDto | AsCodeValueRangeMonitorParamsDto | AsCodeVolumeMonitorParamsDto);
    schedule?: string;
    scheduleTimezone?: string;
    tags?: Array<AsCodeTagReferenceDto>;
    terms?: Array<AsCodeReferenceByIdOrNameDtoImpl>;
    version: number;
};

export namespace AsCodeMonitorDto {

    export enum kind {
        WORKSPACE = 'Workspace',
        MONITOR = 'Monitor',
        ASSET = 'Asset',
        SOURCE = 'Source',
        LINEAGE = 'Lineage',
    }


}
