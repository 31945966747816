/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeJoinConditionDto = {
    kind: AsCodeJoinConditionDto.kind;
};

export namespace AsCodeJoinConditionDto {

    export enum kind {
        EQUALITY = 'Equality',
    }


}
