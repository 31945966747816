/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IntegrationLastRun = {
    status: IntegrationLastRun.status;
    timestamp: number;
};

export namespace IntegrationLastRun {

    export enum status {
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
    }


}
