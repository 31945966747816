<script setup lang="ts">import { computed as _computed } from 'vue';

import STextField from '@/components/STextField/STextField.vue';
import { isRequired, isInteger, minValue } from '@/utils/rules';
import { inject, onMounted } from 'vue';

import type { VForm } from '@/@types/types';

const vuetifyForm = inject<VForm>('form');

const props = defineProps({
  notRequired: { type: Boolean }
});

const rules = _computed(() => {
  if (props.notRequired) return [isInteger, minValue(0)];
  return [isRequired, isInteger, minValue(0)];
});

onMounted(() => {
  vuetifyForm?.validate();
});

</script>

<template lang="pug">
STextField(
  v-bind="$attrs"
  v-on="$listeners"
  type="number"
  :rules="rules"
  :min="0"
  data-cy="amount-selector"
  hide-details="auto" dense outlined )

</template>
