/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCollaborationToolConfigDto } from '../models/CreateCollaborationToolConfigDto';
import type { GetCollaborationToolItemDto } from '../models/GetCollaborationToolItemDto';
import type { GetJiraConfigDto } from '../models/GetJiraConfigDto';
import type { GetServiceNowConfigDto } from '../models/GetServiceNowConfigDto';
import type { GetServiceNowTemplateResponseDto } from '../models/GetServiceNowTemplateResponseDto';
import type { PatchCollaborationToolConfigDto } from '../models/PatchCollaborationToolConfigDto';
import type { PatchServiceNowTemplateRequestDto } from '../models/PatchServiceNowTemplateRequestDto';
import type { PostServiceNowTemplateRequestDto } from '../models/PostServiceNowTemplateRequestDto';
import type { PostServiceNowTemplateTestRequestDto } from '../models/PostServiceNowTemplateTestRequestDto';
import type { SearchCollectionGetCollaborationToolConfigResponseDto } from '../models/SearchCollectionGetCollaborationToolConfigResponseDto';
import type { SearchCollectionGetCollaborationToolWebhookUrlDto } from '../models/SearchCollectionGetCollaborationToolWebhookUrlDto';
import type { SearchCollectionGetServiceNowTemplateResponseDto } from '../models/SearchCollectionGetServiceNowTemplateResponseDto';
import type { SearchCollectionJiraIssueTypeDto } from '../models/SearchCollectionJiraIssueTypeDto';
import type { SearchCollectionJiraProjectDto } from '../models/SearchCollectionJiraProjectDto';
import type { SearchCollectionServiceNowIncidentFormFieldDto } from '../models/SearchCollectionServiceNowIncidentFormFieldDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollaborationToolService {

    /**
     * Get all Collaboration Tool configs
     * @returns SearchCollectionGetCollaborationToolConfigResponseDto Successfully retrieved Collaboration Tool configs
     * @throws ApiError
     */
    public static getAllCollaborationToolConfigs(): CancelablePromise<SearchCollectionGetCollaborationToolConfigResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/configs',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch Collaboration Tool config by type
     * @returns any Successfully updated Collaboration Tool config
     * @throws ApiError
     */
    public static patchCollaborationToolConfigByType({
        requestBody,
    }: {
        requestBody: PatchCollaborationToolConfigDto,
    }): CancelablePromise<(GetJiraConfigDto | GetServiceNowConfigDto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/collaboration-tools/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Connect your Collaboration Tool to Sifflet
     * @returns any Successfully connected Collaboration Tool
     * @throws ApiError
     */
    public static connectCollaborationTool({
        requestBody,
    }: {
        requestBody: CreateCollaborationToolConfigDto,
    }): CancelablePromise<(GetJiraConfigDto | GetServiceNowConfigDto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/collaboration-tools/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete Collaboration Tool config by type
     * @returns void
     * @throws ApiError
     */
    public static deleteCollaborationToolConfigByType({
        type,
    }: {
        type: 'JIRA' | 'SERVICENOW',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/collaboration-tools/configs/{type}',
            path: {
                'type': type,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all issue types for a give Jira project key
     * @returns SearchCollectionJiraIssueTypeDto Successfully retrieved Jira issue types
     * @throws ApiError
     */
    public static getIssueTypesForJiraProject({
        projectKey,
    }: {
        projectKey: string,
    }): CancelablePromise<SearchCollectionJiraIssueTypeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/jira/issue-types',
            query: {
                'projectKey': projectKey,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Projects related to configured Jira instance
     * @returns SearchCollectionJiraProjectDto Successfully retrieved Jira projects
     * @throws ApiError
     */
    public static getAllJiraProjects(): CancelablePromise<SearchCollectionJiraProjectDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/jira/projects',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all new ServiceNow Incident form fields
     * @returns SearchCollectionServiceNowIncidentFormFieldDto Successfully retrieved ServiceNow Incident form fields
     * @throws ApiError
     */
    public static getAllServiceNowIncidentFormFields(): CancelablePromise<SearchCollectionServiceNowIncidentFormFieldDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/servicenow/form-fields',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all ServiceNow templates
     * @returns SearchCollectionGetServiceNowTemplateResponseDto Successfully retrieved ServiceNow templates
     * @throws ApiError
     */
    public static getAllServiceNowTemplates(): CancelablePromise<SearchCollectionGetServiceNowTemplateResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/servicenow/templates',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a new ServiceNow template
     * @returns GetServiceNowTemplateResponseDto Successfully created ServiceNow template
     * @throws ApiError
     */
    public static createServiceNowTemplate({
        requestBody,
    }: {
        requestBody: PostServiceNowTemplateRequestDto,
    }): CancelablePromise<GetServiceNowTemplateResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/collaboration-tools/servicenow/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create ServiceNow test Incident with template
     * @returns GetCollaborationToolItemDto Successfully created a ServiceNow incident with test payload
     * @throws ApiError
     */
    public static testServiceNowTemplate({
        requestBody,
    }: {
        requestBody: PostServiceNowTemplateTestRequestDto,
    }): CancelablePromise<GetCollaborationToolItemDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/collaboration-tools/servicenow/templates/_test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a new ServiceNow template by id
     * @returns void
     * @throws ApiError
     */
    public static deleteServiceNowTemplateById({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/collaboration-tools/servicenow/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a new ServiceNow template by id
     * @returns GetServiceNowTemplateResponseDto Successfully retrieved ServiceNow template by id
     * @throws ApiError
     */
    public static getServiceNowTemplateById({
        id,
    }: {
        id: string,
    }): CancelablePromise<GetServiceNowTemplateResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/servicenow/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a new ServiceNow template by id
     * @returns GetServiceNowTemplateResponseDto Successfully updated ServiceNow template by id
     * @throws ApiError
     */
    public static patchServiceNowTemplateById({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: PatchServiceNowTemplateRequestDto,
    }): CancelablePromise<GetServiceNowTemplateResponseDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/collaboration-tools/servicenow/templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Collaboration Tool webhook urls
     * @returns SearchCollectionGetCollaborationToolWebhookUrlDto Successfully retrieved Collaboration Tool webhooks urls
     * @throws ApiError
     */
    public static getAllCollaborationToolWebhookUrls(): CancelablePromise<SearchCollectionGetCollaborationToolWebhookUrlDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/webhook-urls',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
