/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetCustomMetadataEntryValueResponseDto = {
    countByAssetType: Record<string, number>;
    id: string;
    type: GetCustomMetadataEntryValueResponseDto.type;
};

export namespace GetCustomMetadataEntryValueResponseDto {

    export enum type {
        LABEL = 'LABEL',
        USER = 'USER',
        STRING = 'STRING',
    }


}
