<script setup lang="ts">import { computed as _computed } from 'vue';

import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import {
 IncidentDetailDto, IssueDetailsDto, IncidentService, IncidentLightDto,
} from '@/api';
import eventBus, { EventType } from '@/utils/eventBus';
import authModule from '@/store/modules/auth';
import store from '@/store';
import i18n from '@/i18n';
import IncidentStatus from '@/components/incidents/Incident-Status.vue';
import type { SiffletButtonColor, SiffletButtonVariant } from '@/components/SButton/SButton.vue';
import SMenu from '@/components/SMenu/SMenu.vue';

type StatusSelectorProps = {
  incidents: IssueDetailsDto[] | IncidentLightDto[];
  variant: SiffletButtonVariant;
  color: SiffletButtonColor;
}

type StatusSelectorEmits = {
  (event: 'update', ids: string[]): void;
}

const auth = getModule(authModule, store);
const props = defineProps({
  incidents: null,
  variant: null,
  color: null
});
const emit = defineEmits(["update"]);

const canWriteIncident = _computed(() => auth.userActions['monitoring.incident.responder']);

const notifySuccess = () => {
  Vue.notify({
    type: 'success',
    text: i18n.t('assets.status_success') as string,
  });
};

const setStatus = async (status: IncidentDetailDto.status | IncidentLightDto.status, qualification: IncidentDetailDto.qualification | null) => {
  const promises = props.incidents.map((incident) => IncidentService.patchIncident({
    id: incident.id,
    requestBody: {
      status,
      ...(qualification && { qualification }),
    },
  }));
  await Promise.all(promises);
  notifySuccess();
  emit('update', props.incidents.map((incident) => incident.id));
  eventBus.$emit(EventType.REFRESH_ISSUE);
};

const items = _computed(() => [
  {
    component: IncidentStatus,
    props: {
      status: IncidentDetailDto.status.OPEN,
    },
    action: () => setStatus(IncidentDetailDto.status.OPEN, null),
  },
  {
    component: IncidentStatus,
    props: {
      status: 'IN_PROGRESS',
    },
    action: () => setStatus(IncidentDetailDto.status.IN_PROGRESS, null),
  },
  {
    component: IncidentStatus,
    props: {
      status: 'CLOSED',
    },
    items: [
      {
        title: i18n.t('incidents.mark_monitors_as_passing'),
      },
      {
        text: i18n.t('incidents.qualifications.REVIEWED'),
        secondary_text: i18n.t('incidents.recommended'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.QUALIFIED_MONITORS_REVIEWED),
      },
      {
        text: i18n.t('incidents.qualifications.NO_ACTION_NEEDED'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.QUALIFIED_MONITORS_NO_ACTION_NEEDED),
      },
      {
        text: i18n.t('incidents.qualifications.FALSE_POSITIVE'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.QUALIFIED_MONITORS_FALSE_POSITIVE),
      },
      {
        isDivider: true,
      },
      {
        title: i18n.t('incidents.do_not_mark_monitors_as_passing'),
      },
      {
        text: i18n.t('incidents.qualifications.REVIEWED'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.REVIEWED),
      },
      {
        text: i18n.t('incidents.qualifications.NO_ACTION_NEEDED'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.NO_ACTION_NEEDED),
      },
      {
        text: i18n.t('incidents.qualifications.FALSE_POSITIVE'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.FALSE_POSITIVE),
      },
      {
        text: i18n.t('incidents.qualifications.FIXED'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.FIXED),
      },
      {
        text: i18n.t('incidents.qualifications.DUPLICATE'),
        action: () => setStatus(IncidentDetailDto.status.CLOSED, IncidentDetailDto.qualification.DUPLICATE),
      },
    ],
  },
]);

</script>

<template lang="pug">

SMenu( :items="items" )
  template( v-slot:activator='{ on, value }')
    SButton(
      :disabled='!canWriteIncident'
      :icon="(value ? 'icon-chevron-up' : 'icon-chevron-down')"
      :text="$t('incidents.status')"
      icon-alignment="right"
      :variant="props.variant"
      :color="props.color"
      v-on='on')
</template>
