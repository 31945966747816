/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeNotificationReferenceDto = {
    kind: AsCodeNotificationReferenceDto.kind;
};

export namespace AsCodeNotificationReferenceDto {

    export enum kind {
        SLACK = 'Slack',
        EMAIL = 'Email',
        MICROSOFT_TEAMS = 'MicrosoftTeams',
        JIRA = 'Jira',
        SERVICE_NOW = 'ServiceNow',
        WEBHOOK = 'Webhook',
    }


}
