// Legacy
export type LegacyValueMode = 'COUNT' | 'PERCENTAGE'

export type LegacyDynamicThresholdSettings = {
  valueMode: LegacyValueMode | null,
  comparisonType: 'dynamic',
  sensitivity: number | null,
  ignoreOneBound?: boolean,
  boundChoice?: 'prediction is greater than the expected upper bound' | 'prediction is less than the expected lower bound' | null,
}

export type LegacyFreshnessStaticThresholdSettings = {
  comparisonType: 'static',
}

export type LegacyFreshnessDynamicThresholdSettings = {
  comparisonType: 'dynamic',
  sensitivity: number,
}

export type LegacyFreshnessThresholdSettings = LegacyFreshnessStaticThresholdSettings | LegacyFreshnessDynamicThresholdSettings

export type LegacyStaticAndRelativeThresholdSettings = {
  valueMode: LegacyValueMode | null,
  comparisonType: 'static',
  comparisonMode: 'ABSOLUTE' | 'DIFFERENCE' | 'PERCENTAGE_DIFFERENCE',
  schemaKey: 'onlyMin' | 'minAndMax' | 'onlyMax',
  minOp: '<=' | '<' | null,
  min: number | null,
  maxOp: '<=' | '<' | null
  max: number | null,
}

export type LegacyStaticThresholdSettings = LegacyStaticAndRelativeThresholdSettings & {
  comparisonMode: 'ABSOLUTE',
}

export type LegacyExactThresholdSettings = {
  comparisonType: 'exact',
}

export type LegacyStandardThresholdSettings =
  LegacyDynamicThresholdSettings
  | LegacyStaticAndRelativeThresholdSettings
  | LegacyExactThresholdSettings

export type LegacyInterlinkedThresholdSettings =
  LegacyDynamicThresholdSettings
  | LegacyStaticThresholdSettings
  | LegacyExactThresholdSettings

export type LegacyDistributionThresholdSettings =
  LegacyDynamicThresholdSettings
  | ({
    comparisonType: 'static',
    staticThreshold?: number | null,
    alertIfDeletedCategory?: boolean | null,
    alertIfNewCategory?: boolean | null,
  })

export type LegacyThresholdSettings = (LegacyDynamicThresholdSettings | LegacyFreshnessThresholdSettings | LegacyStaticAndRelativeThresholdSettings | LegacyStaticThresholdSettings | LegacyStandardThresholdSettings | LegacyInterlinkedThresholdSettings | LegacyDistributionThresholdSettings)

// New
export enum ThresholdModeEnum {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
  STATIC_FRESHNESS = 'STATIC_FRESHNESS',
  RELATIVE = 'RELATIVE',
  EXACT = 'EXACT',
}

export enum ThresholdValueModeEnum {
  COUNT = 'COUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum ThresholdBoundsEnum {
  LOWER_AND_UPPER = 'LOWER_AND_UPPER',
  LOWER = 'LOWER',
  UPPER = 'UPPER',
}

export interface StaticThresholdSettings {
  valueMode: ThresholdValueModeEnum | null;
  thresholdMode: ThresholdModeEnum.STATIC
  bounds: ThresholdBoundsEnum | null;
  min: number | null;
  isMinInclusive: boolean | null;
  max: number | null;
  isMaxInclusive: boolean | null;
}

export enum RelativeThresholdModeEnum {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}

export interface RelativeThresholdSettings {
  valueMode: ThresholdValueModeEnum | null;
  thresholdMode: ThresholdModeEnum.RELATIVE;
  mode: RelativeThresholdModeEnum;
  bounds: ThresholdBoundsEnum | null;
  min: number | null;
  isMinInclusive: boolean | null;
  max: number | null;
  isMaxInclusive: boolean | null;
}

export interface DynamicThresholdSettings {
  valueMode: ThresholdValueModeEnum | null;
  thresholdMode: ThresholdModeEnum.DYNAMIC;
  sensitivity: number | null;
  bounds: ThresholdBoundsEnum | null;
}

export interface ExactThresholdSettings {
  valueMode: ThresholdValueModeEnum | null;
  thresholdMode: ThresholdModeEnum.EXACT;
}

export interface StaticFreshnessThresholdSettings {
  valueMode: ThresholdValueModeEnum | null;
  thresholdMode: ThresholdModeEnum.STATIC_FRESHNESS;
}

export type ThresholdSettings = StaticThresholdSettings | StaticFreshnessThresholdSettings | RelativeThresholdSettings | DynamicThresholdSettings | ExactThresholdSettings;

// Options
export type ThresholdModeOptionsCommon = {
  type: ThresholdModeEnum,
  description?: string,
  getDefault: () => ThresholdSettings,
  convertThresholdToLegacy?: (thresholdParams: ThresholdSettings) => LegacyThresholdSettings | null,
}

export type DynamicThresholdModeOptions = ThresholdModeOptionsCommon & {
  type: ThresholdModeEnum.DYNAMIC,
  hasBoundChoice: boolean,
}

export type StaticThresholdModeOptions = ThresholdModeOptionsCommon & {
  type: ThresholdModeEnum.STATIC,
}

export type RelativeThresholdModeOptions = ThresholdModeOptionsCommon & {
  type: ThresholdModeEnum.RELATIVE,
}

export type ExactThresholdModeOptions = ThresholdModeOptionsCommon & {
  type: ThresholdModeEnum.EXACT,
}

export type StaticFreshnessThresholdModeOptions = ThresholdModeOptionsCommon & {
  type: ThresholdModeEnum.STATIC_FRESHNESS,
}

export type ThresholdModeOptions = DynamicThresholdModeOptions | StaticThresholdModeOptions | StaticFreshnessThresholdModeOptions | RelativeThresholdModeOptions | ExactThresholdModeOptions

export enum ValueUnitEnum {
  PERCENTAGE
}

export type ValuesOptions = {
  min?: number,
  max?: number,
  isInteger?: boolean,
  unit?: ValueUnitEnum,
}

export type ThresholdSettingsOptions = {
  modes?: ThresholdModeOptions[],
  defaultMode?: ThresholdModeEnum,
  valueModes?: ThresholdValueModeEnum[],
  values: ValuesOptions,
  convertThresholdToLegacy?: (thresholdParams: ThresholdSettings) => LegacyThresholdSettings | null,
}

export type MonitorSettingsOptions = {
  thresholdSettings?: ThresholdSettingsOptions,
  scheduleSettings?: 'hourly' | 'daily',
  canClone?: boolean,
  hasGroupBy?: boolean,
  hasNoGroupByColumn?: boolean,
  hasField?: boolean,
  hasFields?: boolean,
  hasMetrics?: boolean,
  hasNullValues?: boolean,
  hasValueList?: boolean,
  hasValueRange?: boolean,
  hasSql?: boolean,
  hasCustomMetrics?: boolean,
  hasCorrelatedMetrics?: boolean,
  hasRegex?: boolean,
  hasWhere?: boolean,
  hasPartitioning?: boolean,
  hasTimeSettings?: boolean,
  hasNoWindowColumn?: boolean,
}
