import {
 DatasetBriefDto,
 DatasetLightDto,
 FieldSearchResultDto,
 JsonNode,
 RuleCatalogAssetDtoV2,
 RuleDetailsDto,
 RuleDto,
 RuleInfoDto,
 TimeSeriesPoint,
 UserProviderDto,
} from '@/api';
import { SChipColors } from '@/components/SChipTypes';
import {
  RunStatusValues,
  MonitorModeEnum,
  type MonitoredDatasetLightDto,
  type MonitorAggregatedData,
  type RunStatusTypes,
} from '@/modules/monitors/@types/monitor-types';
import { ThresholdModeEnum } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';

/**
 * Get the displayable status definition, with  for a given status
 * @param status status value
 * @returns complete status definition
 */
export function getMonitorStatusDefinition(status: RunStatusTypes | undefined): { icon: string, color: SChipColors, text: string } {
  const availableStatusDefinitions = {
    [RunStatusValues.PENDING]: {
      icon: 'icon-clock',
      color: SChipColors.NEUTRAL,
      text: 'monitors.run_status_pending',
    },
    [RunStatusValues.NOT_EVALUATED]: {
      icon: 'icon-circle-slash',
      color: SChipColors.NEUTRAL,
      text: 'monitors.run_status_not_evaluated',
    },
    [RunStatusValues.RUNNING]: {
      icon: 'icon-circle-loading',
      color: SChipColors.NEUTRAL,
      text: 'monitors.run_status_running',
    },
    [RunStatusValues.SUCCESS]: {
      icon: 'icon-check-circle-fill',
      color: SChipColors.SUCCESS,
      text: 'monitors.run_status_success',
    },
    [RunStatusValues.PASSING]: {
      icon: 'icon-check-circle-fill',
      color: SChipColors.SUCCESS,
      text: 'monitors.run_status_passing',
    },
    [RunStatusValues.FAILED]: {
      icon: 'icon-error-circle-fill',
      color: SChipColors.FAILURE,
      text: 'monitors.run_status_failed',
    },
    [RunStatusValues.FAILURE]: {
      icon: 'icon-error-circle-fill',
      color: SChipColors.FAILURE,
      text: 'monitors.run_status_failed',
    },
    [RunStatusValues.FAILING]: {
      icon: 'icon-error-circle-fill',
      color: SChipColors.FAILURE,
      text: 'monitors.run_status_failing',
    },
    [RunStatusValues.TECHNICAL_ERROR]: {
      icon: 'icon-tool-fill',
      color: SChipColors.WARNING,
      text: 'monitors.run_status_technical_error',
    },
    [RunStatusValues.REQUIRES_YOUR_ATTENTION]: {
      icon: 'icon-warning-fill',
      color: SChipColors.REQUIRES_ATTENTION,
      text: 'monitors.run_status_requires_your_attention',
    },
    [RunStatusValues.NEEDS_ATTENTION]: {
      icon: 'icon-warning-fill',
      color: SChipColors.WARNING,
      text: 'monitors.run_status_requires_attention',
    },
    [RunStatusValues.SKIPPED_DATASOURCE_ALREADY_RUNNING]: {
      icon: 'icon-check-circle-fill',
      color: SChipColors.NEUTRAL,
      text: 'monitors.run_status_already_running',
    },
    default: {
      icon: 'icon-circle-fill',
      color: SChipColors.NEUTRAL,
      text: '',
    },
  };
  return status && availableStatusDefinitions[status] ? availableStatusDefinitions[status] : availableStatusDefinitions.default;
}

/**
 * Spread monitored fields into datasets
 * @param datasets datasets
 * @param datasetFields monitored dataset fields
 * @returns datasets where each dataset has its monitored fields
 */
export function parseMonitoredDatasets(datasets: DatasetLightDto[] | DatasetBriefDto[], datasetFields: FieldSearchResultDto[]): MonitoredDatasetLightDto[] {
  return datasets.map((dataset) => ({
      ...dataset,
      datasetFields: datasetFields.filter((field) => field.datasetId === dataset.id),
    }));
}

/**
 * Remove duplicates in monitored datasets
 * @param datasets monitored datasets
 * @returns deduplicated monitored datasets
 */
export function deduplicateMonitoredDatasets(datasets: MonitoredDatasetLightDto[]): MonitoredDatasetLightDto[] {
  const uniqueDatasets = new Map<string, MonitoredDatasetLightDto>();
  datasets.forEach((dataset) => {
    uniqueDatasets.set(dataset.id, dataset);
  });
  return Array.from(uniqueDatasets.values());
}

/**
 * Parse monitor scan mode from template name and rule params
 * @param ruleTemplateName
 * @param ruleParams loosely typed rule params list
 * @returns normalized monitor mode
 */
export function parseMonitorMode(ruleTemplateName: string, ruleParams: JsonNode | undefined): MonitorModeEnum | undefined {
  if (ruleTemplateName === 'dbt') {
    return MonitorModeEnum.DBT;
  }
  if (ruleParams) {
    if (ruleParams.hasTimeWindow) {
      return MonitorModeEnum.INCREMENTAL_SCAN;
    }
    return MonitorModeEnum.FULL_DATA_SCAN;
  }
  return undefined;
}

/**
 * Parse monitor threshold mode from monitor rule params
 * @param ruleParams loosely typed rule params list
 * @returns normalized threshold mode
 */
export function parseMonitorThresholdMode(ruleParams: JsonNode): ThresholdModeEnum | undefined {
  if (!ruleParams) {
    return undefined;
  }
  if (ruleParams.comparisonType === 'dynamic') {
    return ThresholdModeEnum.DYNAMIC;
  }
  if (ruleParams.comparisonType === 'static'
    && ruleParams.comparisonMode === 'ABSOLUTE') {
    return ThresholdModeEnum.STATIC;
  }
  if (ruleParams.comparisonType === 'static'
    && (ruleParams.comparisonMode === 'DIFFERENCE' || ruleParams.comparisonMode === 'PERCENTAGE_DIFFERENCE')) {
    return ThresholdModeEnum.RELATIVE;
  }
  if (ruleParams.comparisonType === 'exact') {
    return ThresholdModeEnum.EXACT;
  }
  return undefined;
}

/**
 * Returns the list of points with their matching qualifications
 * @param datapoints list of datapoints
 * @param qualifications list of qualifications
 * @deprecated TimeSeriesPoint already contains qualification
 */
export function parseDatapointQualifications(datapoints: TimeSeriesPoint[]): TimeSeriesPoint[] {
  return datapoints.map((datapoint): TimeSeriesPoint => ({
      ...datapoint,
    }));
}

/**
 * Return the formatted string for the expected range value
 * @param datapoint datapoint to compute the expected range value
 * @param formatValue conversion function to format the number value to corresponding string
 * @returns formatted string for the expected range value
 */
export function computeExpectedRangeValue(datapoint: TimeSeriesPoint, formatValue: (value: number) => string): string {
  let expectedRangeValue: string;
  if (datapoint.ymin == null && datapoint.ymax == null) {
      expectedRangeValue = '–';
  } else if (datapoint.ymin == null) {
      expectedRangeValue = `≤ ${formatValue(datapoint.ymax!)}`;
  } else if (datapoint.ymax == null) {
      expectedRangeValue = `≥ ${formatValue(datapoint.ymin)}`;
  } else if (formatValue(datapoint.ymin) === formatValue(datapoint.ymax)) {
    expectedRangeValue = formatValue(datapoint.ymin);
  } else {
    expectedRangeValue = `${formatValue(datapoint.ymin)}–${formatValue(datapoint.ymax)}`;
  }
  return expectedRangeValue;
}

/**
 * Monitor data aggregation (conversion) from RuleCatalogAssetDtoV2 to MonitorAggregatedData, for consistency
 * @param rule data to be converted
 * @returns converted data
 */
export function aggregateMonitorDataFromRuleCatalogAssetDtoV2(monitor: RuleCatalogAssetDtoV2): MonitorAggregatedData {
  // Force typing conversion as rule.lastRunStatus.status and RuleInfoDto.lastRunStatus are the same enum, but typing differs
  const ruleStatus = monitor.ruleStatus?.ruleStatus ? monitor.ruleStatus?.ruleStatus as unknown as RuleInfoDto.ruleStatus : undefined;
  return {
    ...monitor,
    ruleStatus,
    lastRunTimestamp: monitor.ruleStatus?.latestRunDate,
    monitoredDatasetFields: [],
  };
}

/**
 * Monitor data aggregation from multiple DTOs to MonitorAggregatedData, for consistency
 * @param monitor
 * @param monitorInfo
 * @param monitorDetails
 * @returns aggregated data
 */
export function aggregateMonitorDataFromMultipleDto(monitor: RuleDto | null, monitorInfo: RuleInfoDto | null, monitorDetails: RuleDetailsDto | null): MonitorAggregatedData | null {
  if (!monitor || !monitorInfo || !monitorDetails) {
    return null;
  }
  return {
    createdBy: monitorDetails?.createdByProvider as (UserProviderDto | undefined),
    creationMethod: monitor?.creationMethod,
    datasets: monitor?.datasets,
    description: monitor?.description,
    hasAiRecommendations: false,
    id: monitor?.id || '',
    ruleStatus: monitorInfo?.ruleStatus,
    lastWeekStatuses: undefined,
    monitoredDatasetFields: monitor?.monitoredDatasetFields || [],
    name: monitorInfo?.name || '',
    ruleParams: monitorDetails?.ruleParams,
    ruleTemplateName: monitor?.ruleTemplateName || '',
    schedule: monitorInfo?.schedule,
    sourcePlatform: monitorInfo?.sourcePlatform || RuleInfoDto.sourcePlatform.SIFFLET,
    tags: monitorInfo?.tags,
    terms: monitorDetails?.terms || [],
  };
}
