<script setup lang="ts">import { computed as _computed } from 'vue';

import { watch, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router/composables';
import { getModule } from 'vuex-module-decorators';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';

const route = useRoute();
const dashboard = getModule(dashboardModule, store);

const values = _computed(() => dashboard.values);
const setValues = (filter: { [key: string]: Array<string> }) => dashboard.setValues(filter);

onBeforeMount(() => {
  dashboard.routeChanged(route);
  dashboard.setValuesFromQuery();
});

watch(() => route.query, () => {
  dashboard.routeChanged(route);
  dashboard.setValuesFromQuery();
}, { deep: true, immediate: true });

</script>

<template lang="pug">
.d-flex
  TagSelector.ml-4.mt-1(
  :value="values.tag"
  @input="setValues({tag: $event})"
  filterMode)
</template>
