/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatasetBriefDto } from './DatasetBriefDto';
import type { GetCollaborationToolItemDto } from './GetCollaborationToolItemDto';
import type { RuleBriefDto } from './RuleBriefDto';
import type { UserDto } from './UserDto';

export type IncidentExternalDto = {
    collaborationToolItems: Array<GetCollaborationToolItemDto>;
    compromisedAssets: number;
    criticality: number;
    datasets: Array<DatasetBriefDto>;
    id: string;
    issueNo: number;
    lastModifiedDate?: number;
    lastOccurredDate: number;
    name: string;
    owners: Array<UserDto>;
    qualification: IncidentExternalDto.qualification;
    rules: Array<RuleBriefDto>;
    siffletUrl: string;
    status: IncidentExternalDto.status;
    triggerTime: number;
};

export namespace IncidentExternalDto {

    export enum qualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        REVIEWED = 'REVIEWED',
        DUPLICATE = 'DUPLICATE',
        AUTOMATIC = 'AUTOMATIC',
        QUALIFIED_MONITORS_REVIEWED = 'QUALIFIED_MONITORS_REVIEWED',
        QUALIFIED_MONITORS_NO_ACTION_NEEDED = 'QUALIFIED_MONITORS_NO_ACTION_NEEDED',
        QUALIFIED_MONITORS_FALSE_POSITIVE = 'QUALIFIED_MONITORS_FALSE_POSITIVE',
    }

    export enum status {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
