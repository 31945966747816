/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateCustomMetadataEntryLabelValueDto } from './UpdateCustomMetadataEntryLabelValueDto';
import type { UpdateCustomMetadataEntryStringValueDto } from './UpdateCustomMetadataEntryStringValueDto';
import type { UpdateCustomMetadataEntryUserValueDto } from './UpdateCustomMetadataEntryUserValueDto';

export type UpdateCustomMetadataRequestDto = {
    authorizedAssets: Array<'TABLE_AND_VIEW' | 'DASHBOARD' | 'PIPELINE' | 'DECLARATIVE_ASSET' | 'MONITOR'>;
    entries: Array<(UpdateCustomMetadataEntryLabelValueDto | UpdateCustomMetadataEntryStringValueDto | UpdateCustomMetadataEntryUserValueDto)>;
    isAllowMultipleValues: boolean;
    isShowInFilter: boolean;
    name: string;
    type: UpdateCustomMetadataRequestDto.type;
};

export namespace UpdateCustomMetadataRequestDto {

    export enum type {
        LABEL = 'LABEL',
        USER = 'USER',
        STRING = 'STRING',
    }


}
