/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetDto } from '../models/AssetDto';
import type { AssetFullSchemaDto } from '../models/AssetFullSchemaDto';
import type { AssetPatchDto } from '../models/AssetPatchDto';
import type { AssetsCatalogDto } from '../models/AssetsCatalogDto';
import type { AssetSearchCriteria } from '../models/AssetSearchCriteria';
import type { AssetUriDto } from '../models/AssetUriDto';
import type { AssetUrnDto } from '../models/AssetUrnDto';
import type { AuthorizedAssetHeaderDto } from '../models/AuthorizedAssetHeaderDto';
import type { CsvContentDto } from '../models/CsvContentDto';
import type { DagAssetOverview } from '../models/DagAssetOverview';
import type { DashboardAssetOverview } from '../models/DashboardAssetOverview';
import type { DatasetAssetOverview } from '../models/DatasetAssetOverview';
import type { DatasetGeneratingTransformationDto } from '../models/DatasetGeneratingTransformationDto';
import type { DatasetParamsDto } from '../models/DatasetParamsDto';
import type { DeclaredAssetOverview } from '../models/DeclaredAssetOverview';
import type { FieldDto } from '../models/FieldDto';
import type { FieldSearchResultDto } from '../models/FieldSearchResultDto';
import type { ImportReportDto } from '../models/ImportReportDto';
import type { PredictionFeedbackDto } from '../models/PredictionFeedbackDto';
import type { PreviewDto } from '../models/PreviewDto';
import type { PreviewResultDto } from '../models/PreviewResultDto';
import type { SchemaVersionDto } from '../models/SchemaVersionDto';
import type { SearchCollectionTransformationRunDto } from '../models/SearchCollectionTransformationRunDto';
import type { TransformationAssetOverview } from '../models/TransformationAssetOverview';
import type { UnauthorizedAssetHeaderDto } from '../models/UnauthorizedAssetHeaderDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetService {

    /**
     * @deprecated
     * Get search results and search filters for asset
     * @returns AssetsCatalogDto Successfully fetch assets
     * @throws ApiError
     */
    public static getAll({
        sort,
        textSearch,
        healthStatus,
        assetFilterType,
        usage,
        tag,
        term,
        owner,
        ingestion,
        datasource,
        urn,
        domain,
        searchInFieldsAndDescriptions,
        page,
        itemsPerPage = 25,
    }: {
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * Filter on given health statuses
         */
        healthStatus?: Array<'CRITICAL' | 'AT_RISK' | 'HEALTHY' | 'UNMONITORED' | 'NOT_SUPPORTED'>,
        /**
         * Filter on given asset types
         */
        assetFilterType?: Array<string>,
        /**
         * Filter on given usage types
         */
        usage?: Array<'UNSUPPORTED' | 'LOW' | 'MEDIUM' | 'HIGH'>,
        /**
         * Filter on given tag ids
         */
        tag?: Array<string>,
        /**
         * Filter on given term ids
         */
        term?: Array<string>,
        /**
         * Filter on given owner ids
         */
        owner?: Array<string>,
        /**
         * Filter on given asset ingestion
         */
        ingestion?: Array<'DECLARATIVE' | 'SIFFLET_SOURCED'>,
        /**
         * Filter on given datasource ids
         */
        datasource?: Array<string>,
        /**
         * Filter on given assets urn
         */
        urn?: Array<string>,
        /**
         * Domain searched
         */
        domain?: string,
        /**
         * Search on fields and field descriptions
         */
        searchInFieldsAndDescriptions?: boolean,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
    }): CancelablePromise<AssetsCatalogDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets',
            query: {
                'sort': sort,
                'textSearch': textSearch,
                'healthStatus': healthStatus,
                'assetFilterType': assetFilterType,
                'usage': usage,
                'tag': tag,
                'term': term,
                'owner': owner,
                'ingestion': ingestion,
                'datasource': datasource,
                'urn': urn,
                'domain': domain,
                'searchInFieldsAndDescriptions': searchInFieldsAndDescriptions,
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Confirm import asset descriptions (dataset descriptions and field descriptions)
     * @returns ImportReportDto Successfully confirm import asset descriptions
     * @throws ApiError
     */
    public static confirmImportAssetDescriptions({
        importId,
    }: {
        importId: string,
    }): CancelablePromise<ImportReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/assets/_confirm-import-descriptions',
            query: {
                'importId': importId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Export asset descriptions
     * @returns CsvContentDto Successfully export asset descriptions
     * @throws ApiError
     */
    public static exportAssetDescriptions({
        sort,
        textSearch,
        healthStatus,
        assetFilterType,
        usage,
        tag,
        term,
        owner,
        ingestion,
        datasource,
        urn,
        domain,
        searchInFieldsAndDescriptions,
        page,
        itemsPerPage = 25,
    }: {
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * Filter on given health statuses
         */
        healthStatus?: Array<'CRITICAL' | 'AT_RISK' | 'HEALTHY' | 'UNMONITORED' | 'NOT_SUPPORTED'>,
        /**
         * Filter on given asset types
         */
        assetFilterType?: Array<string>,
        /**
         * Filter on given usage types
         */
        usage?: Array<'UNSUPPORTED' | 'LOW' | 'MEDIUM' | 'HIGH'>,
        /**
         * Filter on given tag ids
         */
        tag?: Array<string>,
        /**
         * Filter on given term ids
         */
        term?: Array<string>,
        /**
         * Filter on given owner ids
         */
        owner?: Array<string>,
        /**
         * Filter on given asset ingestion
         */
        ingestion?: Array<'DECLARATIVE' | 'SIFFLET_SOURCED'>,
        /**
         * Filter on given datasource ids
         */
        datasource?: Array<string>,
        /**
         * Filter on given assets urn
         */
        urn?: Array<string>,
        /**
         * Domain searched
         */
        domain?: string,
        /**
         * Search on fields and field descriptions
         */
        searchInFieldsAndDescriptions?: boolean,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
    }): CancelablePromise<CsvContentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/_export-descriptions',
            query: {
                'sort': sort,
                'textSearch': textSearch,
                'healthStatus': healthStatus,
                'assetFilterType': assetFilterType,
                'usage': usage,
                'tag': tag,
                'term': term,
                'owner': owner,
                'ingestion': ingestion,
                'datasource': datasource,
                'urn': urn,
                'domain': domain,
                'searchInFieldsAndDescriptions': searchInFieldsAndDescriptions,
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Export asset descriptions template
     * @returns CsvContentDto Successfully export asset descriptions template
     * @throws ApiError
     */
    public static exportAssetDescriptionsTemplate(): CancelablePromise<CsvContentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/_export-descriptions-template',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Pre-import asset descriptions (dataset descriptions and field descriptions)
     * @returns ImportReportDto Successfully pre-import asset descriptions
     * @throws ApiError
     */
    public static preImportAssetDescriptions({
        requestBody,
    }: {
        requestBody: CsvContentDto,
    }): CancelablePromise<ImportReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/assets/_pre-import-descriptions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Convert uri to urn
     * @returns AssetUrnDto Successfully fetch urn
     * @throws ApiError
     */
    public static convertUriToUrn({
        requestBody,
    }: {
        requestBody: AssetUriDto,
    }): CancelablePromise<AssetUrnDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/assets/convert-uri-to-urn',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all catalog assets
     * @returns AssetsCatalogDto Successfully fetch assets
     * @throws ApiError
     */
    public static getAllAssets({
        requestBody,
    }: {
        requestBody: AssetSearchCriteria,
    }): CancelablePromise<AssetsCatalogDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/assets/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset
     * @returns AssetDto Successfully fetch asset
     * @throws ApiError
     */
    public static getAssetByUrn({
        urn,
    }: {
        urn: string,
    }): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}',
            path: {
                'urn': urn,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch Asset
     * @returns any Successfully patch Asset
     * @throws ApiError
     */
    public static patchAsset({
        urn,
        requestBody,
    }: {
        urn: string,
        requestBody: AssetPatchDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/assets/{urn}',
            path: {
                'urn': urn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get fields with type option
     * @returns FieldSearchResultDto Successfully fetch asset fields
     * @throws ApiError
     */
    public static getAssetFieldsWithTypeOption({
        urn,
        type,
    }: {
        urn: string,
        type: 'any' | 'date' | 'time' | 'temporal' | 'temporal_butterfly' | 'numeric' | 'group',
    }): CancelablePromise<Array<FieldSearchResultDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/_get-fields',
            path: {
                'urn': urn,
            },
            query: {
                'type': type,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static sendPredictionFeedback({
        urn,
        requestBody,
    }: {
        urn: string,
        requestBody: PredictionFeedbackDto,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/assets/{urn}/_send-prediction-feedback',
            path: {
                'urn': urn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset generating transformation information
     * @returns DatasetGeneratingTransformationDto Successfully fetch asset generating transformation information
     * @throws ApiError
     */
    public static getAssetGeneratingTransformationByUrn({
        urn,
    }: {
        urn: string,
    }): CancelablePromise<DatasetGeneratingTransformationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/generating-transformation',
            path: {
                'urn': urn,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset generating transformation runs information
     * @returns SearchCollectionTransformationRunDto Successfully fetch asset generating transformation runs information
     * @throws ApiError
     */
    public static getAssetGeneratingTransformationRunsByUrn({
        urn,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        urn: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionTransformationRunDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/generating-transformation-runs',
            path: {
                'urn': urn,
            },
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset header
     * @returns any Successfully fetch asset
     * @throws ApiError
     */
    public static getAssetHeaderByUrn({
        urn,
    }: {
        urn: string,
    }): CancelablePromise<(AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/header',
            path: {
                'urn': urn,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset overview params
     * @returns any Successfully fetch asset overview
     * @throws ApiError
     */
    public static getAssetOverviewByUrn({
        urn,
    }: {
        urn: string,
    }): CancelablePromise<(DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | DeclaredAssetOverview | TransformationAssetOverview)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/overview',
            path: {
                'urn': urn,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch Asset Params
     * @returns any Successfully patch Asset Params
     * @throws ApiError
     */
    public static patchAssetParams({
        urn,
        requestBody,
    }: {
        urn: string,
        requestBody?: DatasetParamsDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/assets/{urn}/params',
            path: {
                'urn': urn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset preview data
     * @returns PreviewResultDto Successfully fetch asset preview data
     * @throws ApiError
     */
    public static getAssetPreviewByUrn({
        urn,
        requestBody,
    }: {
        urn: string,
        requestBody: PreviewDto,
    }): CancelablePromise<PreviewResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/assets/{urn}/preview',
            path: {
                'urn': urn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset full schema
     * @returns AssetFullSchemaDto Successfully fetch asset schema
     * @throws ApiError
     */
    public static getAssetFullSchemaByUrnAndVersion({
        urn,
        version,
    }: {
        urn: string,
        version: string,
    }): CancelablePromise<AssetFullSchemaDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/schema-full/{version}',
            path: {
                'urn': urn,
                'version': version,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset schema versions
     * @returns SchemaVersionDto Successfully fetch asset schema versions
     * @throws ApiError
     */
    public static getAssetSchemaVersionsByUrn({
        urn,
    }: {
        urn: string,
    }): CancelablePromise<Array<SchemaVersionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/schema-versions',
            path: {
                'urn': urn,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single asset schema
     * @returns FieldDto Successfully fetch asset schema
     * @throws ApiError
     */
    public static getAssetSchemaByUrnAndVersion({
        urn,
        version,
    }: {
        urn: string,
        version: string,
    }): CancelablePromise<Array<FieldDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/assets/{urn}/schema/{version}',
            path: {
                'urn': urn,
                'version': version,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
