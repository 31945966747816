/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetCount = {
    assetType: AssetCount.assetType;
    count: number;
};

export namespace AssetCount {

    export enum assetType {
        ACCOUNT = 'ACCOUNT',
        ENVIRONMENT = 'ENVIRONMENT',
        DATABASE = 'DATABASE',
        SCHEMA = 'SCHEMA',
        PROJECT = 'PROJECT',
        DATASET = 'DATASET',
        CLUSTER = 'CLUSTER',
        CATALOG = 'CATALOG',
        WORKSPACE = 'WORKSPACE',
        SPACE = 'SPACE',
    }


}
