/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentEventDto } from './IncidentEventDto';

export type CollaborationToolStatusChangeEventDto = (IncidentEventDto & {
    incidentQualification?: CollaborationToolStatusChangeEventDto.incidentQualification;
    incidentStatus?: CollaborationToolStatusChangeEventDto.incidentStatus;
    itemKey?: string;
    itemType?: CollaborationToolStatusChangeEventDto.itemType;
    itemUrl?: string;
} & {
    incidentStatus: CollaborationToolStatusChangeEventDto.incidentStatus;
    itemKey: string;
    itemType: CollaborationToolStatusChangeEventDto.itemType;
});

export namespace CollaborationToolStatusChangeEventDto {

    export enum incidentQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        REVIEWED = 'REVIEWED',
        DUPLICATE = 'DUPLICATE',
        AUTOMATIC = 'AUTOMATIC',
        QUALIFIED_MONITORS_REVIEWED = 'QUALIFIED_MONITORS_REVIEWED',
        QUALIFIED_MONITORS_NO_ACTION_NEEDED = 'QUALIFIED_MONITORS_NO_ACTION_NEEDED',
        QUALIFIED_MONITORS_FALSE_POSITIVE = 'QUALIFIED_MONITORS_FALSE_POSITIVE',
    }

    export enum incidentStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }

    export enum itemType {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
