import { ThresholdValueModeEnum, type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { staticSettings, exactSettings, dynamicSettings } from '@/modules/monitors/monitor-wizard/threshold-settings/thresholdSettings';
import { interlinkedThresholdToLegacy } from '@/modules/monitors/monitor-wizard/threshold-settings/legacyConverter';

const SiffletInterlinkedMetricsRule: MonitorSettingsOptions = {
  thresholdSettings: {
    valueModes: [ThresholdValueModeEnum.COUNT, ThresholdValueModeEnum.PERCENTAGE],
    modes: [
      {
        ...dynamicSettings,
        description: 'monitor_wizard.threshold_settings.threshold_mode.alternate_descriptions.dynamic_alternate_description',
        convertThresholdToLegacy: interlinkedThresholdToLegacy,
        hasBoundChoice: false,
      },
      {
        ...staticSettings,
        description: 'monitor_wizard.threshold_settings.threshold_mode.alternate_descriptions.static_alternate_description',
        convertThresholdToLegacy: interlinkedThresholdToLegacy,
      },
      {
        ...exactSettings,
        convertThresholdToLegacy: interlinkedThresholdToLegacy,
      },
    ],
    values: { min: 0 },
  },
  canClone: false,
  hasTimeSettings: true,
  hasNoWindowColumn: true,
  hasCorrelatedMetrics: true,
  hasGroupBy: true,
  hasNoGroupByColumn: true,
};

export default SiffletInterlinkedMetricsRule;
