<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import type { VForm } from '@/@types/types';
import { onMounted, useSlots, inject } from 'vue';

import { VTextField } from 'vuetify/lib/components';

interface STextFieldProps {
  eagerlyValidate?: boolean;
}
const props = defineProps({
  eagerlyValidate: { type: Boolean, default: false }
});

const vuetifyForm = inject<VForm>('form');

const slots = useSlots();

const textField = _ref<(InstanceType<typeof VTextField> & { validate: () => boolean }) | null>(null);

const hasPrependText = _computed(() => !!slots['prepend-text']);

onMounted(() => {
  if (props.eagerlyValidate) {
    vuetifyForm?.validate();
  }
});

</script>

<template lang="pug">
v-text-field(
  ref="textField"
  v-bind="$attrs"
  v-on="$listeners"
  data-cy="s-text-field"
  outlined dense
  :clearable="!['false', false].includes($attrs.clearable)")

  template( #prepend-inner )
    .v-text-field__prependText-inner( v-if="hasPrependText" )
      slot( name="prepend-text" )

  template( #append-outer )
    slot( name="append-outer" )

</template>

<style lang="scss" scoped>
.v-text-field__prependText-inner {
  background-color: var(--v-bgPageSecondary-base);
  position: relative;
  margin-top: -8px;
  margin-left: -12px;
  margin-right: 8px;
  padding: 11px 11px 0;
  height: 36px;
  color: var(--v-textSecondary-base);
}

// By default, vuetify adds a margin on .v-input__append-outer so we remove it to allow for more composability and less magic
.v-text-field :deep(.v-input__append-outer) {
  margin-top: 0 !important;
}
</style>
