/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GraphDto } from '../models/GraphDto';
import type { GroupDecryptedValuesDto } from '../models/GroupDecryptedValuesDto';
import type { HistogramGraphDto } from '../models/HistogramGraphDto';
import type { MultiMetricsGraphDto } from '../models/MultiMetricsGraphDto';
import type { RuleGroupRequestDto } from '../models/RuleGroupRequestDto';
import type { RuleOverviewGroupCountPerStatusDto } from '../models/RuleOverviewGroupCountPerStatusDto';
import type { RuleRunDebugDto } from '../models/RuleRunDebugDto';
import type { RuleRunDto } from '../models/RuleRunDto';
import type { SearchCollectionRuleRunDetailsByGroupDto } from '../models/SearchCollectionRuleRunDetailsByGroupDto';
import type { SearchCollectionRuleRunDto } from '../models/SearchCollectionRuleRunDto';
import type { TimeSeriesGraphDto } from '../models/TimeSeriesGraphDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RuleRunService {

    /**
     * Debug a Sifflet Rule run
     * @returns RuleRunDebugDto Sifflet Rule run debug result
     * @throws ApiError
     */
    public static siffletRuleRunDebug({
        id,
        runId,
        requestBody,
    }: {
        id: string,
        runId: string,
        requestBody?: RuleGroupRequestDto,
    }): CancelablePromise<RuleRunDebugDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rules/{id}/runs/{runId}/_debug',
            path: {
                'id': id,
                'runId': runId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Download failing results of a Sifflet Rule run. The CSV created contains the first 20000 rows sent by the Database
     * @returns binary Sifflet Rule run download failing results
     * @throws ApiError
     */
    public static siffletRuleRunDownloadByRun({
        id,
        runId,
        exportFormat = 'CSV',
        filteredColumnNames,
        requestBody,
    }: {
        id: string,
        runId: string,
        exportFormat?: 'CSV' | 'CSV_FOR_EXCEL' | 'TSV',
        filteredColumnNames?: Array<string>,
        requestBody?: RuleGroupRequestDto,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rules/{id}/runs/{runId}/_download',
            path: {
                'id': id,
                'runId': runId,
            },
            query: {
                'exportFormat': exportFormat,
                'filteredColumnNames': filteredColumnNames,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch a Rule Run details
     * @returns SearchCollectionRuleRunDetailsByGroupDto Successfully fetch rule run details
     * @throws ApiError
     */
    public static getSiffletRuleRunDetails({
        id,
        runId,
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        id: string,
        runId: string,
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionRuleRunDetailsByGroupDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rules/{id}/runs/{runId}/details',
            path: {
                'id': id,
                'runId': runId,
            },
            query: {
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single Sifflet rule graph
     * @returns any Successfully fetch rule graph by group
     * @throws ApiError
     */
    public static getSiffletRuleGraphByRunAndGroup({
        id,
        runId,
        requestBody,
    }: {
        id: string,
        runId: string,
        requestBody: RuleGroupRequestDto,
    }): CancelablePromise<Array<(GraphDto | HistogramGraphDto | MultiMetricsGraphDto | TimeSeriesGraphDto)>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rules/{id}/runs/{runId}/groups/_graph',
            path: {
                'id': id,
                'runId': runId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch a Rule Run details for overview tab (dynamic statuses)
     * @returns SearchCollectionRuleRunDetailsByGroupDto Successfully fetch rule run details for overview
     * @throws ApiError
     */
    public static getSiffletRuleRunOverviewDetails({
        ruleId,
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
        ruleGroupStatus,
    }: {
        ruleId: string,
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
        ruleGroupStatus?: 'NOT_EVALUATED' | 'PASSING' | 'NEEDS_ATTENTION' | 'FAILING',
    }): CancelablePromise<SearchCollectionRuleRunDetailsByGroupDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rules/{ruleId}/overview/details',
            path: {
                'ruleId': ruleId,
            },
            query: {
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
                'ruleGroupStatus': ruleGroupStatus,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch a Sifflet rule group count per status for overview
     * @returns RuleOverviewGroupCountPerStatusDto Successfully fetch rule group count per status for overview
     * @throws ApiError
     */
    public static getSiffletRuleOverviewGroupCountPerStatus({
        ruleId,
    }: {
        ruleId: string,
    }): CancelablePromise<RuleOverviewGroupCountPerStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rules/{ruleId}/overview/group-count-per-status',
            path: {
                'ruleId': ruleId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Decrypt rule run groups
     * @returns GroupDecryptedValuesDto Successfully fetch rule run groups decryption
     * @throws ApiError
     */
    public static decryptRuleRunGroups({
        runId,
    }: {
        runId: string,
    }): CancelablePromise<GroupDecryptedValuesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules/runs/{runId}/decrypt',
            path: {
                'runId': runId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Sifflet rule runs by rule id
     * @returns SearchCollectionRuleRunDto Successfully fetch Sifflet rule Runs
     * @throws ApiError
     */
    public static getSiffletRuleRuns({
        id,
        status,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        id: string,
        /**
         * Filter by run status
         */
        status?: Array<'PENDING' | 'RUNNING' | 'SUCCESS' | 'REQUIRES_YOUR_ATTENTION' | 'TECHNICAL_ERROR' | 'FAILED'>,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionRuleRunDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules/{id}/runs',
            path: {
                'id': id,
            },
            query: {
                'status': status,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a Sifflet rule run by rule id and run id
     * @returns RuleRunDto Successfully fetch Sifflet rule run
     * @throws ApiError
     */
    public static getSiffletRuleRun({
        id,
        runId,
        expand,
    }: {
        id: string,
        runId: string,
        expand?: Array<'VALUES'>,
    }): CancelablePromise<RuleRunDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules/{id}/runs/{runId}',
            path: {
                'id': id,
                'runId': runId,
            },
            query: {
                'expand': expand,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
