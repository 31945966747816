/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DagRunDto = {
    dagId?: string;
    endDate?: number;
    executionDate?: number;
    externalTrigger?: boolean;
    note?: string;
    runType?: DagRunDto.runType;
    startDate?: number;
    state?: DagRunDto.state;
};

export namespace DagRunDto {

    export enum runType {
        BACKFILL = 'backfill',
        MANUAL = 'manual',
        SCHEDULED = 'scheduled',
        DATASET_TRIGGERED = 'dataset_triggered',
    }

    export enum state {
        QUEUED = 'queued',
        RUNNING = 'running',
        SUCCESS = 'success',
        FAILED = 'failed',
    }


}
