/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EntityCustomMetadataEntryLabelDto } from './EntityCustomMetadataEntryLabelDto';
import type { EntityCustomMetadataEntryStringDto } from './EntityCustomMetadataEntryStringDto';
import type { EntityCustomMetadataEntryUserDto } from './EntityCustomMetadataEntryUserDto';

export type EntityCustomMetadataDto = {
    entries: Array<(EntityCustomMetadataEntryLabelDto | EntityCustomMetadataEntryStringDto | EntityCustomMetadataEntryUserDto)>;
    id: string;
    name: string;
    type: EntityCustomMetadataDto.type;
};

export namespace EntityCustomMetadataDto {

    export enum type {
        LABEL = 'LABEL',
        USER = 'USER',
        STRING = 'STRING',
    }


}
