/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DomainDto } from './DomainDto';
import type { TimeZoneDto } from './TimeZoneDto';

export type UserDetailDto = {
    active?: boolean;
    authTypes?: Array<'SAML2' | 'LOGIN_PASSWORD'>;
    createdBy?: string;
    createdDate?: number;
    defaultAdmin?: boolean;
    defaultSupport?: boolean;
    domains?: Array<DomainDto>;
    id: string;
    lastLoginDate?: number;
    lastModifiedDate?: number;
    login: string;
    modifiedBy?: string;
    name?: string;
    resetPassword?: boolean;
    roles: string;
    timeZoneData?: TimeZoneDto;
    type: UserDetailDto.type;
};

export namespace UserDetailDto {

    export enum type {
        INTERNAL = 'INTERNAL',
        SUPPORT = 'SUPPORT',
    }


}
