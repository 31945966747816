/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DomainDynamicInputDto } from './DomainDynamicInputDto';

export type DomainDto = {
    assetCount?: number;
    assets: Array<string>;
    canDelete?: boolean;
    containsDeletedFilters?: boolean;
    createdBy?: string;
    createdDate?: number;
    description?: string;
    domainInputMethod: DomainDto.domainInputMethod;
    dynamicInput?: DomainDynamicInputDto;
    id: string;
    isAllDomain: boolean;
    lastModifiedDate?: number;
    modifiedBy?: string;
    name: string;
};

export namespace DomainDto {

    export enum domainInputMethod {
        STATIC = 'STATIC',
        DYNAMIC = 'DYNAMIC',
    }


}
