/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetSelectionDto = {
    assetType: AssetSelectionDto.assetType;
    children: Array<AssetSelectionDto>;
    id: string;
    included?: boolean;
    name: string;
    newChildrenIncluded?: boolean;
    uniqueId: string;
};

export namespace AssetSelectionDto {

    export enum assetType {
        ACCOUNT = 'ACCOUNT',
        ENVIRONMENT = 'ENVIRONMENT',
        DATABASE = 'DATABASE',
        SCHEMA = 'SCHEMA',
        PROJECT = 'PROJECT',
        DATASET = 'DATASET',
        CLUSTER = 'CLUSTER',
        CATALOG = 'CATALOG',
        WORKSPACE = 'WORKSPACE',
        SPACE = 'SPACE',
    }


}
