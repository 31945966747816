/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { OpenAPI, CookiesService } from '@/api';
import getEnv from '@/utils/env';
import en from '@/locales/en.json';
import axios, { AxiosError } from 'axios';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import authModule from '@/store/modules/auth';
import qs from 'qs';
import catalogModule from '@/store/modules/catalog';
import { ApiProblemSchema } from './models/ApiProblemSchema';

const AUTH_EXPIRATION_COOKIE_NAME = 'auth_refresh_cookie';

// Show only one api error every X milliseconds, to avoid too many errors
const API_NOTIFICATION_DELAY = 500;

// set API defaults
const baseURL = getEnv('VUE_APP_API_URL')!;
const timeout = Number(getEnv('VUE_APP_API_TIMEOUT') || 300000);

// Cache info
const DATA_CATALOG_URL = '/ui/v1/assets/search';
const catalogStore = getModule(catalogModule, store);

let showingError = false;
let refreshingToken = false;

OpenAPI.BASE = baseURL;
OpenAPI.WITH_CREDENTIALS = true;
axios.defaults.baseURL = baseURL; // for old services that don't use OpenAPI yet
axios.defaults.timeout = timeout;
axios.defaults.withCredentials = true;

axios.defaults.paramsSerializer = (params) => qs.stringify(params, { indices: false });

axios.interceptors.request.use(async (request) => {
  // Check expiry of auth token and refresh it if needed
  const expirationCookie = document.cookie.split(';').find((cookie) => cookie.includes(AUTH_EXPIRATION_COOKIE_NAME));
  const expirationCookieTimestamp = Number(expirationCookie?.split('=')[1]);
  const isExpiredAuthenticationToken = expirationCookieTimestamp && Date.now() > expirationCookieTimestamp;
  if (!refreshingToken && isExpiredAuthenticationToken) {
    try {
      refreshingToken = true;
      await CookiesService.refreshAuthCookie();
      refreshingToken = false;
    } catch {
      refreshingToken = false;
    }
  }

  // spread request params from url if generated by openapi
  if (request?.url?.includes('?')) {
    const [url, query] = request.url.split('?');
    const params = qs.parse(query!);
    request.url = url;
    request.params = params;
  }

  // set page size query param to zero based pagination
  if (request?.params?.pageNum) {
    request.params.pageNum -= 1;
  }

  // set page size query param to zero based pagination
  if (request?.params?.page) {
    request.params.page -= 1;
  }

  // set page size query params to zero based pagination when in post request
  if (request?.data?.page) {
    request.data.page -= 1;
  }

  // rewrite url if there is no version, this should be removed when all services are updated
  if (!request.url?.includes('/v1')) {
    request.url = `/v1${request.url}`;
  }

  // override header for yaml generation
  if (request.url?.includes('/rules/_convert-to-code')) {
    request.headers!.Accept = 'application/x-yaml';
  }

  if (request.method === 'post' && request.url?.includes(DATA_CATALOG_URL)) {
    // Check if the request is a POST request targeting the data catalog URL.
    if (catalogStore.getData && catalogStore.getETag) {
        // If the cached ETag exists, add it to the request headers as 'If-None-Match'.
        // This header allows the server to respond with a 304 (Not Modified) status if the cached version is still valid,
        // avoiding unnecessary data transfer and improving efficiency.
        request.headers = request.headers || {};
        request.headers['If-None-Match'] = catalogStore.getETag;
    }
}
  return request;
}, (error) => Promise.reject(error));

axios.interceptors.response.use(
  (response) => {
    // Attach response headers to the data object when the response data is an object.
    // This allows headers, such as the ETag, to be easily accessible alongside the response body.
    // The type check ensures this operation is safe and prevents runtime errors when the data is not an object
    // (e.g., in cases of plain text, HTML, or empty responses).
    if (typeof response.data === 'object' && response.data !== null && response.config.url?.includes(DATA_CATALOG_URL)) {
      response.data.headers = response.headers;
    }
    return response;
  },
  (error: AxiosError<ApiProblemSchema>) => {
    const auth = getModule(authModule, store);

    // Handle "Not Modified" (304) responses POST request targeting the data catalog URL
    // This logic is designed to optimize performance by leveraging cached data when the server indicates
    // that the requested resource has not changed (via a 304 status).
    if (error.response?.status === 304 && error.config?.method === 'post' && error.config.url?.includes(DATA_CATALOG_URL)) {
      const cachedData = catalogStore.getData;
      if (cachedData) {
          // Cached data is found and returned as a simulated successful (200) response.
          return Promise.resolve({
              status: 200,
              statusText: 'OK (from cache)',
              config: error.config,
              headers: error.response?.headers || {},
              data: cachedData,
          });
      }
    }

    if (!showingError) {
      if (error.response?.status === 401) {
        // AUTHENTICATION_FAILED
        if (!(error.response?.data?.detail === 'Invalid login or password')) {
          auth.resetDomain(); // Reset domain in case admin remove the active domain of a user
          // redirect to login without calling the logout endpoint (as we are already disconnected)
          store.dispatch('auth/frontEndOnlyLogout', { persistRedirect: true });

          Vue.notify({
            title: 'Notification',
            text: 'Your session has expired. This is a security measure. Please sign in again.',
          });
        }
      } else if (error.response?.status === 426) {
        // UPGRADE_REQUIRED
        Vue.notify({
          title: error.response?.data?.title || 'Notification',
          text: error.response?.data?.detail,
        });
      } else if (error.code === 'ERR_CANCELED') {
        // Request canceled
      } else {
        // handle other errors
        Vue.notify({
          title: error.response?.data?.title || 'Error',
          type: 'error',
          text: error.response?.data?.detail || en.common.messages.SERVER_DOWN_ERROR_MESSAGE,
        });
      }

      showingError = true;
      setTimeout(() => {
        showingError = false;
      }, API_NOTIFICATION_DELAY);
    }

    return Promise.reject(error);
  },
);

export default axios;
