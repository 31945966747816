<script setup lang="ts">import { computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { type DrawerItem } from './Navigation-Drawer-Items';

const auth = getModule(authModule, store);

interface NavigationDrawerItemProps {
  item: DrawerItem;
  displaySettingsDrawer: boolean;
}

const props = defineProps({
  item: null,
  displaySettingsDrawer: { type: Boolean }
});

const isEnabled = _computed(() => props.item.actions
?.map((action) => auth.userActions[action])
.some((value) => value));

const hideElement = (e: MouseEvent) => {
  const target = e.currentTarget as HTMLElement;
  target.style.display = 'none';
  setTimeout(() => {
    target.style.display = 'block';
  }, 100);
};

</script>

<template lang="pug">

  .menu-item(
    v-if="isEnabled"
    :class="item.class"
    class="flex flex-grow-0")

    .px-4.py-3.group-menu-title( v-if="!item.to" )
      .d-flex.flex-fill.align-center
        SIcon( v-if="item.icon" :icon="item.icon" color="textOnsurface" )
        span.textOnsurface--text.ml-2.text-caption.font-weight-bold {{ $t(item.title) }}

    v-list-item(
      v-if="item.to"
      :to="item.to"
      @click.stop
      :disabled="item.disabled"
      link
      :class="{ 'settings-list-item': displaySettingsDrawer }"
      :data-cy="item.dataCy" )

      .d-flex.flex-fill

        v-list-item-icon( v-if="item.icon" )
          v-icon.ml-1( size="25" ) {{ item.icon }}

        v-list-item-title
          .ml-1.d-flex.justify-space-between
            span.text.text-h6 {{ $t(item.title) }}
            v-chip( small outlined color="textOnsurface" v-if="item.beta" )
              .white--text {{ $t('common.words.beta') }}
            v-icon( small v-if="item.dropdown" class="float-right" ) icon-chevron-right

    .menu-item-sub-menu( v-if="item.dropdown" @click.capture="hideElement" :class="item.itemsPosition" )
      v-card(
        v-if="item.items && item.itemsPosition"
        color="textPrimary"
        elevation="6"
        tile )
        NavigationDrawerItem(
          :displaySettingsDrawer="displaySettingsDrawer"
          v-for='sub_item in item.items'
          :key="sub_item.title"
          :item="sub_item" )
    div( v-else )
      NavigationDrawerItem.group-menu-item(
        :displaySettingsDrawer="displaySettingsDrawer"
        v-for='sub_item in item.items'
        :key="sub_item.title"
        :item="sub_item")
</template>

<style lang="scss">
.menu-item {

  position: relative;
  width: 100%;

  &:hover {
    .menu-item-sub-menu {
      opacity: 1;
      visibility: visible;
      transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
    }
  }

  .v-list-item {
    min-height: 56px;
    height: 56px;
    padding: 12px;
    border-radius: 0 !important;
    transition: background-color .3s;
    background-color: transparent;

    &__icon {
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin: 0;
    }

    &__icon:first-child {
      margin-right: 0;
    }

    &::before {
      border-radius: 0 !important;
    }

    &:not(:last-child):not(:only-child) {
      // display: none;
      margin-bottom: 0;
    }

    .text,
    .icon {
      color: var(--v-textTertiary-base);
      transition: color .3s;
    }

    &:hover {

      background-color: var(--v-bgNavSecondaryHover-base);

      .text,
      .icon {
        color: var(--v-textNavSecondaryPressed-base);
      }
    }

    &--active {

      background-color: var(--v-bgNavSecondaryPressed-base);

      .text {
        font-weight: 500;
      }

      .text,
      .icon {
        color: var(--v-textOnsurface-base);
      }

      &:after {
        content: "";
        background-color: var(--v-bgPrimary-base);
        position: absolute;
        width: 3px;
        height: 30px;
        left: 0px
      }
    }

  }

  .group-menu-title {
    min-height: 48px;
    height: 48px;
  }
  .settings-list-item {
    min-height: 48px;
    height: 48px;
    padding: 12px;
  }

  &-sub-menu {
    background-color: transparent !important;
    position: absolute;
    right: 0;
    left: 100%;
    min-width: 260px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    padding-left: 5px;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }
  }
}

.v-navigation-drawer--mini-variant {

  .v-list-item__title {
    visibility: hidden !important;
    display: none;
  }

  .menu-item {
    &:hover {
      .menu-item-sub-menu {
        .v-list-item__title {
          visibility: visible !important;
          display: block;
        }
      }
    }
  }
}

.group-menu-item {
  .v-list-item {
    padding-left: 40px;
  }
}
</style>
