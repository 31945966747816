/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AirflowIntegrationParamsDto } from './AirflowIntegrationParamsDto';
import type { AssetSelectionDto } from './AssetSelectionDto';
import type { AthenaIntegrationParamsDto } from './AthenaIntegrationParamsDto';
import type { BigqueryIntegrationParamsDto } from './BigqueryIntegrationParamsDto';
import type { ComposerIntegrationParamsDto } from './ComposerIntegrationParamsDto';
import type { DatabricksIntegrationParamsDto } from './DatabricksIntegrationParamsDto';
import type { DbtcloudIntegrationParamsDto } from './DbtcloudIntegrationParamsDto';
import type { DbtIntegrationParamsDto } from './DbtIntegrationParamsDto';
import type { DeclarativeIntegrationParamsDto } from './DeclarativeIntegrationParamsDto';
import type { FivetranIntegrationParamsDto } from './FivetranIntegrationParamsDto';
import type { LookerIntegrationParamsDto } from './LookerIntegrationParamsDto';
import type { MicrostrategyIntegrationParamsDto } from './MicrostrategyIntegrationParamsDto';
import type { MssqlIntegrationParamsDto } from './MssqlIntegrationParamsDto';
import type { MwaaIntegrationParamsDto } from './MwaaIntegrationParamsDto';
import type { MysqlIntegrationParamsDto } from './MysqlIntegrationParamsDto';
import type { OracleIntegrationParamsDto } from './OracleIntegrationParamsDto';
import type { PostgresqlIntegrationParamsDto } from './PostgresqlIntegrationParamsDto';
import type { PowerbiIntegrationParamsDto } from './PowerbiIntegrationParamsDto';
import type { QlikIntegrationParamsDto } from './QlikIntegrationParamsDto';
import type { QuicksightIntegrationParamsDto } from './QuicksightIntegrationParamsDto';
import type { RedshiftIntegrationParamsDto } from './RedshiftIntegrationParamsDto';
import type { SnowflakeIntegrationParamsDto } from './SnowflakeIntegrationParamsDto';
import type { SynapseIntegrationParamsDto } from './SynapseIntegrationParamsDto';
import type { TableauIntegrationParamsDto } from './TableauIntegrationParamsDto';

export type CreateIntegrationRequestDto = {
    assetSelectionDto?: AssetSelectionDto;
    cronExpression?: string;
    name: string;
    params: (AirflowIntegrationParamsDto | AthenaIntegrationParamsDto | BigqueryIntegrationParamsDto | ComposerIntegrationParamsDto | DatabricksIntegrationParamsDto | DbtIntegrationParamsDto | DbtcloudIntegrationParamsDto | DeclarativeIntegrationParamsDto | FivetranIntegrationParamsDto | LookerIntegrationParamsDto | MicrostrategyIntegrationParamsDto | MssqlIntegrationParamsDto | MwaaIntegrationParamsDto | MysqlIntegrationParamsDto | OracleIntegrationParamsDto | PostgresqlIntegrationParamsDto | PowerbiIntegrationParamsDto | QlikIntegrationParamsDto | QuicksightIntegrationParamsDto | RedshiftIntegrationParamsDto | SnowflakeIntegrationParamsDto | SynapseIntegrationParamsDto | TableauIntegrationParamsDto);
    secretId?: string;
    type: CreateIntegrationRequestDto.type;
};

export namespace CreateIntegrationRequestDto {

    export enum type {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        POSTGRES = 'POSTGRES',
        ORACLE = 'ORACLE',
        SYNAPSE = 'SYNAPSE',
        POWER_BI = 'POWER_BI',
        LOOKER = 'LOOKER',
        TABLEAU = 'TABLEAU',
        QUICKSIGHT = 'QUICKSIGHT',
        MICROSTRATEGY = 'MICROSTRATEGY',
        QLIK = 'QLIK',
        AIRFLOW = 'AIRFLOW',
        MWAA = 'MWAA',
        COMPOSER = 'COMPOSER',
        DBT = 'DBT',
        DBTCLOUD = 'DBTCLOUD',
        FIVETRAN = 'FIVETRAN',
        DECLARATIVE = 'DECLARATIVE',
        _UNKNOWN_ = '_UNKNOWN_',
    }


}
