/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OAuthIntegrationRedirectDto } from '../models/OAuthIntegrationRedirectDto';
import type { OAuthIntegrationUrlDto } from '../models/OAuthIntegrationUrlDto';
import type { RequestOAuthIntegrationConfigDto } from '../models/RequestOAuthIntegrationConfigDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OAuthIntegrationService {

    /**
     * Get the OAuth Integration authorization URL
     * @returns OAuthIntegrationUrlDto Successfully retrieved OAuth Integration authorization URL
     * @throws ApiError
     */
    public static getOAuthAuthorizationUrl({
        requestBody,
    }: {
        requestBody: RequestOAuthIntegrationConfigDto,
    }): CancelablePromise<OAuthIntegrationUrlDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/oauth/integrations/authorization-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Handle the OAuth Integration redirect
     * @returns any Successfully handled OAuth Integration redirect
     * @throws ApiError
     */
    public static handleOAuthIntegrationRedirect({
        requestBody,
    }: {
        requestBody: OAuthIntegrationRedirectDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/oauth/integrations/redirect',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get the OAuth Integration redirect URL
     * @returns OAuthIntegrationUrlDto Successfully retrieved OAuth Integration redirect URL
     * @throws ApiError
     */
    public static getOAuthIntegrationRedirectUrl({
        provider,
    }: {
        provider: 'SERVICENOW',
    }): CancelablePromise<OAuthIntegrationUrlDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/oauth/integrations/redirect-url',
            query: {
                'provider': provider,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
