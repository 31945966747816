<script setup lang="ts">import { shallowRef as _shallowRef, ref as _ref, computed as _computed } from 'vue';

import { watch, onMounted } from 'vue';
import { VSelect } from 'vuetify/lib/components';
import { CalendarsService, PublicCalendarGetDto } from '@/api';
import i18n from '@/i18n';
import SLabel from '@/components/SLabel.vue';

let calendars = _shallowRef<PublicCalendarGetDto[]>([]);

const selectRef = _ref<(InstanceType<typeof VSelect> & { validate: () => boolean }) | null>(null);
let loading = _ref(false);

const daysOfTheWeekCalendar = [PublicCalendarGetDto.standardCalendar.SUNDAYS, PublicCalendarGetDto.standardCalendar.WEEKENDS];

const allItems = _computed(() => {
  // VJSF automatically adds the current value into the list of possible items, which is not what we want
  const items = calendars.value.filter((t) => t.id);

  const result: { text?: string, value?: string, divider?: boolean, header?: string }[] = [];
  result.push({ header: i18n.t('monitor_wizard.monitor_settings.calendars.public_holidays') });
  for (const item of items.filter((c) => c.standardCalendar && !daysOfTheWeekCalendar.includes(c.standardCalendar))) {
    result.push({ text: item.name, value: item.id });
  }
  result.push({ header: i18n.t('monitor_wizard.monitor_settings.calendars.days_of_the_week') });
  for (const item of items.filter((c) => c.standardCalendar && daysOfTheWeekCalendar.includes(c.standardCalendar))) {
    result.push({ text: item.name, value: item.id });
  }
  result.push({ header: i18n.t('monitor_wizard.monitor_settings.calendars.custom_calendars') });
  for (const item of items.filter((c) => !c.standardCalendar)) {
    result.push({ text: item.name, value: item.id });
  }

  return result;
});

const allRules = _computed(() => [(value: string | string[]) => {
  if (value === null) return true;
  const allowedValues = allItems.value.map((item) => item.value);
  if (Array.isArray(value)) {
    return value.every((val) => allowedValues.includes(val));
  }
  return allowedValues.includes(value);
}]);

const getCalendars = async () => {
  loading.value = true;
  calendars.value = (await CalendarsService.publicGetCalendars()).data;
  loading.value = false;
};

watch(() => allItems.value, () => {
  // Validation is not done by default on allItems update
  selectRef.value?.validate();
});

onMounted(() => {
  getCalendars();
});

</script>

<template lang="pug">
SLabel( as-columns ) {{ $t('monitor_wizard.time_settings.excluded_dates_time_window_label') }}
  v-select(
    slot="input"
    v-bind="$attrs"
    v-on="$listeners"
    ref="selectRef"
    :rules="allRules"
    :items="allItems"
    :loading="loading"
    multiple
    outlined
    dense
    hide-details
    menu-props="offset-y")

</template>

<style lang="scss" scoped>
:deep(.v-subheader) {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--v-textPrimary-base) !important;
}
</style>
