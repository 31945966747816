import { Vue } from 'vue-property-decorator';

/**
 *
 * @param text The text to copy
 * @param successMessage The message toast
 */
const copyToClipboard = (text: string, successMessage: string, successTitle?: string) => {
  navigator.clipboard.writeText(text).then(() => {
    const notificationOptions: { title?: string; text: string; type: string } = {
      text: successMessage,
      type: 'success',
    };

    if (successTitle) {
      notificationOptions.title = successTitle;
    }

    Vue.notify(notificationOptions);
  });
};

export default copyToClipboard;
