<template lang="pug">
div(
  class="codeEditor rounded"
  :class="`${requiredCSS} ${languageCSS} ${resizeBehaviorCSS} ${themeCSS}`"
  :style="`min-height: ${minHeight}px;`"
  ref="codeEditor")
</template>

<script lang="ts">
import {
  Vue, Component, Ref, Prop, Watch, VModel,
} from 'vue-property-decorator';
import { CodeJar } from 'codejar';
import { highlight, languages } from 'prismjs';

@Component
export default class InputCode extends Vue {
  @Ref() codeEditor!: HTMLElement;

  @VModel({ type: String, default: '' }) code!: string;

  @Prop({ default: 'sql' }) readonly language!: 'sql' | 'yaml';

  @Prop({ default: 'vertical' }) readonly resizeBehavior!: 'both' | 'horizontal' | 'vertical' | 'none';

  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean;

  @Prop({ type: Boolean, default: false }) readonly required!: boolean;

  @Prop({ default: 50 }) readonly minHeight!: number;

  jar: null | CodeJar = null;

  get languageCSS() {
    return `language-${this.language}`;
  }

  get resizeBehaviorCSS() {
    return `resize-${this.resizeBehavior}`;
  }

  get requiredCSS() {
    return this.required && !this.code ? 'required' : '';
  }

  get themeCSS() {
    return this.$vuetify.theme.dark ? 'editor-dark' : 'editor-light';
  }

  @Watch('code')
  onValueChanged(value: string) {
    const { jar } = this;
    if (jar && value !== jar.toString()) {
      jar.updateCode(value);
    }
  }

  customHighlight(editor: HTMLElement) {
    const currentCode = editor.textContent || '';
    this.codeEditor.innerHTML = highlight(currentCode, languages[this.language], this.language);
  }

  init() {
    this.jar = CodeJar(this.codeEditor, this.customHighlight);
    if (this.readOnly) {
      this.codeEditor?.setAttribute('contenteditable', 'false');
    }
    this.jar.updateCode(this.code);
    this.jar.onUpdate((newValue) => {
      this.code = newValue;
    });
  }

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  }

  beforeDestroy() {
    if (this.jar) this.jar.destroy();
  }
}
</script>

<style lang="scss">
.codeEditor {
  border: 1px solid var(--v-borderPrimary-base);
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  line-height: 20px;
  padding: 12px;
  tab-size: 4;
  margin-bottom: 14px;
  background: var(--v-bgPagePrimary-base);
  color: var(--v-textPrimary-base);

  &.required {
    border: 1px solid var(--v-borderDanger-base);
  }

  span.keyword {
    color: var(--v-textAi-base);
  }

  span.operator {
    color: var(--v-textCodeOperator-base);
  }

  span.comment {
    color: var(--v-textCodeComment-base);
  }

  span.number {
    color: var(--v-textCodeNumber-base);
  }

  span.string {
    color: var(--v-textCodeString-base);
    ;
  }
}

.resize-horizontal {
  resize: horizontal;
  overflow: hidden;
}

.resize-vertical {
  resize: vertical;
  overflow: hidden;
}

.resize-both {
  resize: both;
  overflow: hidden;
}
</style>
