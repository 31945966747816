<script setup lang="ts">import { ref as _ref } from 'vue';

import { onMounted, inject } from 'vue';
import { VSelect } from 'vuetify/lib/components';

import type { VForm } from '@/@types/types';

const vuetifyForm = inject<VForm>('form');

const items = [
  {
    text: 'Datapoints',
    value: null,
  },
  {
    text: 'Datapoints (months)',
    value: 'monthly',
  },
  {
    text: 'Datapoints (weeks)',
    value: 'weekly',
  },
  {
    text: 'Datapoints (days)',
    value: 'daily',
  },
  {
    text: 'Datapoints (hours)',
    value: 'hourly',
  },
  {
    text: 'Datapoints (30 minutes)',
    value: 'intra-hourly - 30 minutes',
  },
  {
    text: 'Datapoints (20 minutes)',
    value: 'intra-hourly - 20 minutes',
  },
  {
    text: 'Datapoints (15 minutes)',
    value: 'intra-hourly - 15 minutes',
  },
  {
    text: 'Datapoints (10 minutes)',
    value: 'intra-hourly - 10 minutes',
  },
];

const selectRef = _ref<(InstanceType<typeof VSelect> & { validate: () => boolean }) | null>(null);

onMounted(() => {
  vuetifyForm?.validate();
});

</script>

<template lang="pug">
v-select(
  v-bind="$attrs"
  v-on="$listeners"
  ref="selectRef"
  :items="items"
  hide-details="auto"
  outlined
  dense
  menu-props="offset-y")

</template>
