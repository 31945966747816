/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AirflowIntegrationParamsDto } from '../models/AirflowIntegrationParamsDto';
import type { AssetSelectionDto } from '../models/AssetSelectionDto';
import type { AthenaIntegrationParamsDto } from '../models/AthenaIntegrationParamsDto';
import type { BigqueryIntegrationParamsDto } from '../models/BigqueryIntegrationParamsDto';
import type { ComposerIntegrationParamsDto } from '../models/ComposerIntegrationParamsDto';
import type { CreateIntegrationRequestDto } from '../models/CreateIntegrationRequestDto';
import type { DatabricksIntegrationParamsDto } from '../models/DatabricksIntegrationParamsDto';
import type { DbtcloudIntegrationParamsDto } from '../models/DbtcloudIntegrationParamsDto';
import type { DbtIntegrationParamsDto } from '../models/DbtIntegrationParamsDto';
import type { DeclarativeIntegrationParamsDto } from '../models/DeclarativeIntegrationParamsDto';
import type { FivetranIntegrationParamsDto } from '../models/FivetranIntegrationParamsDto';
import type { IntegrationDto } from '../models/IntegrationDto';
import type { IntegrationIngestionRunDto } from '../models/IntegrationIngestionRunDto';
import type { ListIntegrationAssetsRequestDto } from '../models/ListIntegrationAssetsRequestDto';
import type { LookerIntegrationParamsDto } from '../models/LookerIntegrationParamsDto';
import type { MicrostrategyIntegrationParamsDto } from '../models/MicrostrategyIntegrationParamsDto';
import type { MssqlIntegrationParamsDto } from '../models/MssqlIntegrationParamsDto';
import type { MwaaIntegrationParamsDto } from '../models/MwaaIntegrationParamsDto';
import type { MysqlIntegrationParamsDto } from '../models/MysqlIntegrationParamsDto';
import type { OracleIntegrationParamsDto } from '../models/OracleIntegrationParamsDto';
import type { PostgresqlIntegrationParamsDto } from '../models/PostgresqlIntegrationParamsDto';
import type { PowerbiIntegrationParamsDto } from '../models/PowerbiIntegrationParamsDto';
import type { QlikIntegrationParamsDto } from '../models/QlikIntegrationParamsDto';
import type { QuicksightIntegrationParamsDto } from '../models/QuicksightIntegrationParamsDto';
import type { RedshiftIntegrationParamsDto } from '../models/RedshiftIntegrationParamsDto';
import type { SnowflakeIntegrationParamsDto } from '../models/SnowflakeIntegrationParamsDto';
import type { SynapseIntegrationParamsDto } from '../models/SynapseIntegrationParamsDto';
import type { TableauIntegrationParamsDto } from '../models/TableauIntegrationParamsDto';
import type { TestIntegrationConnectionResponseDto } from '../models/TestIntegrationConnectionResponseDto';
import type { UpdateIntegrationRequestDto } from '../models/UpdateIntegrationRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IntegrationService {

    /**
     * Get all integrations
     * @returns IntegrationDto Successfully fetch integration
     * @throws ApiError
     */
    public static getAllIntegrations(): CancelablePromise<Array<IntegrationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a integration
     * @returns IntegrationDto Successfully create integration
     * @throws ApiError
     */
    public static createIntegration({
        requestBody,
    }: {
        requestBody: CreateIntegrationRequestDto,
    }): CancelablePromise<IntegrationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/integrations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test connection with given integration params
     * @returns TestIntegrationConnectionResponseDto Connection test result
     * @throws ApiError
     */
    public static testIntegrationConnection({
        requestBody,
    }: {
        requestBody: CreateIntegrationRequestDto,
    }): CancelablePromise<TestIntegrationConnectionResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/integrations/_test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all integration assets
     * @returns AssetSelectionDto Successfully fetch integration assets
     * @throws ApiError
     */
    public static listIntegrationAssets({
        requestBody,
    }: {
        requestBody: ListIntegrationAssetsRequestDto,
    }): CancelablePromise<AssetSelectionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/integrations/list-integration-assets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all of integration param types
     * @returns any Successfully fetch integration params
     * @throws ApiError
     */
    public static getAllIntegrationParamTypes(): CancelablePromise<Array<(AirflowIntegrationParamsDto | AthenaIntegrationParamsDto | BigqueryIntegrationParamsDto | ComposerIntegrationParamsDto | DatabricksIntegrationParamsDto | DbtIntegrationParamsDto | DbtcloudIntegrationParamsDto | DeclarativeIntegrationParamsDto | FivetranIntegrationParamsDto | LookerIntegrationParamsDto | MicrostrategyIntegrationParamsDto | MssqlIntegrationParamsDto | MwaaIntegrationParamsDto | MysqlIntegrationParamsDto | OracleIntegrationParamsDto | PostgresqlIntegrationParamsDto | PowerbiIntegrationParamsDto | QlikIntegrationParamsDto | QuicksightIntegrationParamsDto | RedshiftIntegrationParamsDto | SnowflakeIntegrationParamsDto | SynapseIntegrationParamsDto | TableauIntegrationParamsDto)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations/params',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete integration by given id
     * @returns void
     * @throws ApiError
     */
    public static deleteIntegrationById({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single integration
     * @returns IntegrationDto Successfully fetch integration
     * @throws ApiError
     */
    public static getIntegrationById({
        id,
    }: {
        id: string,
    }): CancelablePromise<IntegrationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an integration
     * @returns IntegrationDto Successfully update integration
     * @throws ApiError
     */
    public static updateIntegration({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateIntegrationRequestDto,
    }): CancelablePromise<IntegrationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/integrations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Manually trigger of integration metadata ingestion job
     * @returns void
     * @throws ApiError
     */
    public static integrationIngestionManualRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/integrations/{id}/_run',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch an integration ingestion run
     * @returns IntegrationIngestionRunDto Successfully fetch the integration ingestion run
     * @throws ApiError
     */
    public static getIntegrationIngestionRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<IntegrationIngestionRunDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations/{id}/ingestion-run',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
