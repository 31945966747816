/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestPasswordResetDto } from '../models/RequestPasswordResetDto';
import type { ResetPasswordDto } from '../models/ResetPasswordDto';
import type { ValidatePasswordResetTokenResponseDto } from '../models/ValidatePasswordResetTokenResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ResetPasswordService {

    /**
     * Check that password reset token is valid
     * @returns ValidatePasswordResetTokenResponseDto Successfully checked password reset token validity.
     * @throws ApiError
     */
    public static checkPasswordResetTokenValidity({
        token,
    }: {
        token: string,
    }): CancelablePromise<ValidatePasswordResetTokenResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/users/password-reset-tokens/validity',
            query: {
                'token': token,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Request starting a password reset process.
     * @returns any Successfully requested a password reset.
     * @throws ApiError
     */
    public static requestPasswordReset({
        requestBody,
    }: {
        requestBody: RequestPasswordResetDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/users/request-password-reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Reset user password to a given value
     * @returns any Successfully reset password.
     * @throws ApiError
     */
    public static resetPassword({
        requestBody,
    }: {
        requestBody: ResetPasswordDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/users/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
