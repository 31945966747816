/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateCustomMetadataEntryLabelValueDto } from './CreateCustomMetadataEntryLabelValueDto';
import type { CreateCustomMetadataEntryStringValueDto } from './CreateCustomMetadataEntryStringValueDto';
import type { CreateCustomMetadataEntryUserValueDto } from './CreateCustomMetadataEntryUserValueDto';

export type CreateCustomMetadataRequestDto = {
    authorizedAssets: Array<'TABLE_AND_VIEW' | 'DASHBOARD' | 'PIPELINE' | 'DECLARATIVE_ASSET' | 'MONITOR'>;
    entries: Array<(CreateCustomMetadataEntryLabelValueDto | CreateCustomMetadataEntryStringValueDto | CreateCustomMetadataEntryUserValueDto)>;
    isAllowMultipleValues: boolean;
    isShowInFilter: boolean;
    name: string;
    type: CreateCustomMetadataRequestDto.type;
};

export namespace CreateCustomMetadataRequestDto {

    export enum type {
        LABEL = 'LABEL',
        USER = 'USER',
        STRING = 'STRING',
    }


}
