/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentEventDto } from './IncidentEventDto';
import type { UserDto } from './UserDto';

export type StatusChangeIncidentEventDto = (IncidentEventDto & {
    changedByUser?: UserDto;
    firstDate?: number;
    lastDate?: number;
    newQualification?: StatusChangeIncidentEventDto.newQualification | null;
    newStatus?: StatusChangeIncidentEventDto.newStatus;
    oldQualification?: StatusChangeIncidentEventDto.oldQualification | null;
    oldStatus?: StatusChangeIncidentEventDto.oldStatus;
} & {
    firstDate: number;
    lastDate: number;
    newStatus: StatusChangeIncidentEventDto.newStatus;
    oldStatus: StatusChangeIncidentEventDto.oldStatus;
});

export namespace StatusChangeIncidentEventDto {

    export enum newQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        REVIEWED = 'REVIEWED',
        DUPLICATE = 'DUPLICATE',
        AUTOMATIC = 'AUTOMATIC',
        QUALIFIED_MONITORS_REVIEWED = 'QUALIFIED_MONITORS_REVIEWED',
        QUALIFIED_MONITORS_NO_ACTION_NEEDED = 'QUALIFIED_MONITORS_NO_ACTION_NEEDED',
        QUALIFIED_MONITORS_FALSE_POSITIVE = 'QUALIFIED_MONITORS_FALSE_POSITIVE',
    }

    export enum newStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }

    export enum oldQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        REVIEWED = 'REVIEWED',
        DUPLICATE = 'DUPLICATE',
        AUTOMATIC = 'AUTOMATIC',
        QUALIFIED_MONITORS_REVIEWED = 'QUALIFIED_MONITORS_REVIEWED',
        QUALIFIED_MONITORS_NO_ACTION_NEEDED = 'QUALIFIED_MONITORS_NO_ACTION_NEEDED',
        QUALIFIED_MONITORS_FALSE_POSITIVE = 'QUALIFIED_MONITORS_FALSE_POSITIVE',
    }

    export enum oldStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
