/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeDatasetReferenceDto } from './AsCodeDatasetReferenceDto';
import type { AsCodeEqualityJoinConditionDto } from './AsCodeEqualityJoinConditionDto';

export type AsCodeJoinDto = {
    dataset: AsCodeDatasetReferenceDto;
    joinCondition: AsCodeEqualityJoinConditionDto;
    joinType: AsCodeJoinDto.joinType;
};

export namespace AsCodeJoinDto {

    export enum joinType {
        INNER = 'Inner',
        LEFT = 'Left',
        RIGHT = 'Right',
        OUTER = 'Outer',
    }


}
