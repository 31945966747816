<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import { computed, ref, watch } from 'vue';
import makeSafeForCSS from '@/utils/makeSafeCss';
import { DatasetFieldService } from '@/api';
import type { FullSchemaFieldDto, DatasetFieldPatchDto } from '@/api';
import type EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import authModule from '@/store/modules/auth';
import store from '@/store';
import SiffletifyDataFooter from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import i18n from '@/i18n';

interface NestedSchemaProps {
  items: Array<any>;
  loading: boolean;
  enableAISuggestions: boolean;
  versionId: string;
  options?: Partial<DataOptions>;
}

type NestedSchemaEmits = {
  (event: 'updateOptions', options: Partial<DataOptions>): void,
  (event: 'editItem', item: FullSchemaFieldDto[]): void,
  (event: 'updateSelected', selected: string[]): void,
  (event: 'sendPrediction'): void,
}

const headers = [
  {
    text: i18n.t('data-catalog.nested_schema.name'),
    value: 'name',
    cols: '12',
    md: '3',
    align: 'left',
    class: 'pl-md-6',
  },
  {
    text: i18n.t('data-catalog.nested_schema.type'),
    value: 'type',
    cols: '4',
    md: '1',
    align: 'left',
    class: 'pl-md-6',
  },
  {
    text: i18n.t('data-catalog.nested_schema.tags'),
    value: 'tags',
    cols: '12',
    md: '3',
    align: 'left',
    class: 'pl-md-4',
  },
  {
    text: i18n.t('data-catalog.nested_schema.status'),
    value: 'status',
    cols: '4',
    md: '1',
    align: 'left',
    class: 'pl-md-3',
  },
  {
    text: i18n.t('data-catalog.nested_schema.description'),
    value: 'description',
    cols: '12',
    md: '3',
    align: 'left',
    class: 'pl-md-6',
  },
];

const auth = getModule(authModule, store);
const emit = defineEmits(["updateOptions", "editItem", "updateSelected", "sendPrediction"]);
const props = defineProps({
  items: { default: () => [] },
  loading: { type: Boolean, default: false },
  enableAISuggestions: { type: Boolean, default: false },
  versionId: { default: '' },
  options: { default: () => ({}) }
});

const editDialogRef = ref<InstanceType<typeof EditDialog> | null>(null);

const selected = _ref<string[]>([]);
const openedItems = _ref<FullSchemaFieldDto[]>([]);
const menuIsOpen = _ref(false);
let customDialogTitle = _ref('');
let selectedSuggestionID = '';

const options = computed(() => props.options);

const start = _computed(() => (options!.value.page! - 1) * options!.value.itemsPerPage!);
const end = _computed(() => start.value + options!.value.itemsPerPage!);
const itemsFiltered = _computed(() => props.items.slice(start.value, end.value));

const updateOptions = (opt: any) => {
  emit('updateOptions', opt);
};

const canEdit = computed(() => auth.userActions['metadata.asset.catalog-editor']);
const isVersionLatest = computed(() => props.versionId === 'latest');

const id = (item: any) => makeSafeForCSS(`${item.name}_${item.id}`);

const editItem = (item: FullSchemaFieldDto) => {
  emit('editItem', [item]);
};

const openSuggestionDialog = (newId: string, description: string, title: string) => {
  customDialogTitle.value = title;
  selectedSuggestionID = newId;
  editDialogRef!.value!.setEntities([{ description }]);
};

watch(() => selected.value, () => {
  emit('updateSelected', selected.value);
});

const fetchFieldAfterFeedback = () => {
  emit('sendPrediction');
};

const editDialogUpdateHandler = async ({ patches }: { patches: DatasetFieldPatchDto[] }) => {
  await DatasetFieldService.updateDatasetField({
    id: selectedSuggestionID,
    requestBody: { description: patches[0].description },
  });

  emit('sendPrediction');
};
</script>

<template lang="pug">
v-data-table(
  hide-default-header
  hide-default-footer
  :options="options"
  :headers="headers"
  :loading="loading"
  :server-items-length="items.length"
  style="padding: 0"
  class="nestedSchema")

  template(v-slot:loading)
    DataTableGenericPlaceholder

  template( #header="{ props: { headers } }" )
    v-row.ml-4.pa-12.py-3
      v-col(v-for="(header, index) in headers" :key="index" :cols="header.col" :md="header.md" :class="header.class")
        span.text-no-wrap.subtitle-1.font-weight-medium.text--darken-4 {{ header.text }}

  template(v-slot:footer="footer")
    // eslint-disable-next-line vue/valid-v-bind-sync
    SiffletifyDataFooter(:footer.sync="footer.props" :items-per-page-options="[10, 25, 50, 100]" @update="updateOptions")

  template( slot="no-data" )
    v-treeview(
      v-model="selected"
      return-object
      :items="itemsFiltered"
      :selectable="canEdit"
      selection-type="independent"
      item-children="subfields"
      selected-color="primary"
      :open.sync="openedItems"
      )

      template( #label="{ item, index }" )
        EditDialog(
          ref="editDialogRef"
          :fields="['description']"
          :customTitle="customDialogTitle"
          saveAndAddMode
          @update="editDialogUpdateHandler")
        NestedSchemaItem.schema-item-card(
          :item="item"
          :index="index"
          :enableAISuggestions="enableAISuggestions"
          :version-id="versionId"
          @sendPrediction="fetchFieldAfterFeedback"
          @openSuggestionDialog="openSuggestionDialog"
        )

      template( #append="{ item }" )
        v-menu(
          v-if="isVersionLatest"
          v-model="menuIsOpen"
          content-class="border-around"
          nudge-bottom="5" nudge-right="36" min-width="200"
          :attach="`#${id(item)}`"
          transition="slide-x-reverse-transition"
          offset-x offset-y bottom left )

          template(v-slot:activator='{ on, $attrs }')
            v-tooltip(top :disabled="canEdit")
              template(v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs}")
                div(v-on="tooltipOn" v-bind="tooltipAttrs")
                  v-btn(
                    :attrs="$attrs"
                    v-on="on"
                    class="action-button custom-secondary icon-only"
                    color="secondary"
                    :disabled="!canEdit"
                    text
                    @click="editItem(item)" )
                    v-icon(small) icon-edit
              span {{ $t('app.rights.suggestions_no_rights') }}
</template>

<style lang="scss">
.nestedSchema {
  .center {
    display: flex;
    align-items: center;
  }

  .v-data-table__empty-wrapper {
    text-align: left;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    padding-left: 0;
  }

  .v-treeview-node__root {
    min-height: 50px;
    border-top: 1px solid #d7d7d7;

    .action-button {
      opacity: 0;
    }

    &.selected {
      background-color: var(--v-bgAccentPrimary-base);
    }

    &:hover {
      background-color: var(--v-bgPrimaryHover-base);

      .action-button {
        opacity: 1;
      }
    }
  }

  .v-treeview-node__checkbox {
    margin-left: 20px;
  }

  .v-treeview-node__content {
    margin-left: 20px;
  }
}
</style>
