import { render, staticRenderFns } from "./SButton.vue?vue&type=template&id=708cf70c&scoped=true&lang=pug"
import script from "./SButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./SButton.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SButton.vue?vue&type=style&index=0&id=708cf70c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "708cf70c",
  null
  
)

export default component.exports