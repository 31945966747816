/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DescriptionDto } from './DescriptionDto';

export type FieldLevelSearchFieldDto = {
    description?: string;
    entityType: FieldLevelSearchFieldDto.entityType;
    externalDescriptions?: Array<DescriptionDto>;
    name: string;
};

export namespace FieldLevelSearchFieldDto {

    export enum entityType {
        INTEGRATION = 'INTEGRATION',
        DATASOURCE = 'DATASOURCE',
        DATASOURCE_INGESTION_RUN = 'DATASOURCE_INGESTION_RUN',
        DATASET = 'DATASET',
        DASHBOARD = 'DASHBOARD',
        CHART = 'CHART',
        COLLECTION = 'COLLECTION',
        DATASET_FIELD = 'DATASET_FIELD',
        DAG = 'DAG',
        TRANSFORMATION = 'TRANSFORMATION',
        RULE_RUN = 'RULE_RUN',
        INCIDENT = 'INCIDENT',
        USER = 'USER',
        ACCESS_TOKEN = 'ACCESS_TOKEN',
        SIFFLET_RULE = 'SIFFLET_RULE',
        CONFIG = 'CONFIG',
        TAG = 'TAG',
        DOMAIN = 'DOMAIN',
        ALERTING_HOOK = 'ALERTING_HOOK',
        RULE_MONITORING_RECOMMENDATION = 'RULE_MONITORING_RECOMMENDATION',
        DATAPOINT_QUALIFICATION = 'DATAPOINT_QUALIFICATION',
        DECLARED_ASSET = 'DECLARED_ASSET',
        WEBHOOK = 'WEBHOOK',
        SIFFLET_AGENT = 'SIFFLET_AGENT',
        SIFFLET_AGENT_JOB = 'SIFFLET_AGENT_JOB',
        AI_METADATA_PREDICTION = 'AI_METADATA_PREDICTION',
        CUSTOM_METADATA = 'CUSTOM_METADATA',
        CUSTOM_METADATA_ENTRY = 'CUSTOM_METADATA_ENTRY',
    }


}
