import { type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';

const SiffletUniqueRule: MonitorSettingsOptions = {
  hasFields: true,
  hasGroupBy: true,
  hasWhere: true,
  hasPartitioning: true,
  hasTimeSettings: true,
};

export default SiffletUniqueRule;
