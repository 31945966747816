/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCustomMetadataEntryValueDto = {
    type: CreateCustomMetadataEntryValueDto.type;
};

export namespace CreateCustomMetadataEntryValueDto {

    export enum type {
        LABEL = 'LABEL',
        USER = 'USER',
        STRING = 'STRING',
    }


}
