<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import i18n from '@/i18n';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import businessGlossaryModule from '@/store/modules/business-glossary';
import store from '@/store';
import BusinessGlossaryTermsEdit from '@/components/business-glossary/Business-Glossary-Terms-Edit.vue';
import BusinessGlossaryDrawerLinked from '@/components/business-glossary/business-glossary-drawer/Business-Glossary-Drawer-Linked.vue';
import { TermService, TagWithAssetsDto, TagDto } from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import Vue, { onBeforeUnmount, onMounted, watch } from 'vue';
import SRichTextViewer from '@/components/SRichTextViewer.vue';

type BusinessGlossaryDrawerEmits = {
  (event: 'set-title', title: string): void,
}
const emits = defineEmits(["set-title"]);

const DEFAULT_OPTIONS = {
  page: 1,
  itemsPerPage: 25,
};

const auth = getModule(authModule, store);
const terms = getModule(businessGlossaryModule, store);

const businessGlossaryTermsEditRef = _ref<InstanceType<typeof BusinessGlossaryTermsEdit>>();
const modalDialog = _ref<InstanceType<typeof ModalDialog>>();
const activeTab = _ref(0);

let assetFilterType = _ref<('TABLE_AND_VIEW' | 'DASHBOARD' | 'PIPELINE' | 'MONITOR' | 'GENERIC') | undefined >(undefined);

let options = _ref(DEFAULT_OPTIONS);

let term = _ref<TagWithAssetsDto>({
  id: '',
  name: '',
  description: '',
  type: TagWithAssetsDto.type.TERM,
  assets: {
    data: [],
    totalElements: 0,
  },
});

let loading = _ref<boolean>(false);

const linkedLength = _computed(() => term.value?.assetCount || 0);
const description = _computed<string>(() => term.value?.description || '');
const assets = _computed(() => term.value?.assets || []);
const selectedTerm = _computed<TagDto | null>(() => terms.selectedTerm);
const termId = _computed(() => terms.selectedTerm?.id);
const canEdit = _computed(() => auth.userActions['metadata.term.write']);
const canDelete = _computed(() => auth.userActions['metadata.term.write']);

const getTermWithAssets = async () => {
  if (!termId.value) return;
  loading.value = true;
  term.value = await TermService.getTermWithAssets({
    tagId: terms.selectedTerm?.id,
    page: options.value.page,
    itemsPerPage: options.value.itemsPerPage,
    assetFilterType: assetFilterType.value ? [assetFilterType.value] : undefined,
  });
  emits('set-title', term.value.name);
  loading.value = false;
};

const validateCallback = () => {
  terms.searchTerms();
  getTermWithAssets();
};

const editTerm = () => {
  businessGlossaryTermsEditRef.value?.editTerm();
};

const deleteSingleTerm = () => {
  modalDialog.value?.openDialog();
};

const resetTerm = () => {
  term.value = {
    id: '',
    name: '',
    description: '',
    type: TagWithAssetsDto.type.TERM,
    assets: {
      data: [],
      totalElements: 0,
    },
  };
};

const resetOptions = () => {
  options.value = {
    page: 1,
    itemsPerPage: 25,
  };
};

const resetFilter = () => {
  assetFilterType.value = undefined;
};

const init = () => {
  terms.removeSelectedTerm();
  resetTerm();
  resetOptions();
  resetFilter();
  Vue.prototype.$rightDrawer?.closeDrawer();
};

const deleteTerm = async () => {
  modalDialog.value?.closeDialog();
  await TermService.deleteTerm({ id: term.value.id });
  Vue.notify({
    text: i18n.t('glossary.delete_terms_success', { name: term.value.name }),
  });
  await terms.searchTerms();
  init();
};

const updateFilter = (newAssetFilterType: 'TABLE_AND_VIEW' | 'DASHBOARD' | 'PIPELINE' | 'MONITOR' | undefined) => {
  assetFilterType.value = newAssetFilterType;
  getTermWithAssets();
};

watch(() => termId.value, async () => {
  if (termId.value) await getTermWithAssets();
});

watch(() => options.value, () => {
  getTermWithAssets();
});

onMounted(() => {
  getTermWithAssets();
});

onBeforeUnmount(() => {
  init();
});
</script>

<template lang="pug">
.v-container( data-cy="business-glossary-drawer" )

  BusinessGlossaryTermsEdit(
    ref="businessGlossaryTermsEditRef"
    :selected-term="selectedTerm"
    @onValidate="validateCallback" )

  v-row.mb-2( no-gutters )
    v-col( class="text-right" )
      v-btn.mr-3( v-if='canEdit' color="secondary" class="custom-secondary" text outlined @click='editTerm' data-cy="business-glossary-drawer-button-edit")
        v-icon( left ) $edit
        span {{ $t('glossary.edit') }}

      v-btn( v-if="canDelete" color="alert" class="custom-alert" text outlined @click="deleteSingleTerm" data-cy="business-glossary-drawer-button-delete")
        v-icon( left ) $delete
        span {{ $t('glossary.delete') }}

  v-row( no-gutters )
    v-col
      v-tabs( v-model="activeTab" data-cy="business-glossary-drawer-tabs" )
        v-tab {{ $t('glossary.definition') }}
        v-tab( style="min-width: 140px;" ) {{  $t('glossary.data_assets_and_monitors') }} ({{ linkedLength }})

      v-divider

  v-row( no-gutters )
    v-col
      v-tabs-items( v-model="activeTab" )
        v-tab-item
          .my-2
            SRichTextViewer(:content="description")
        v-tab-item
          BusinessGlossaryDrawerLinked.my-2(
            v-model="options"
            :assets="assets"
            :loading="loading"
            @update-filter="updateFilter" )

  ModalDialog( ref="modalDialog" :width="'400px'" :title="$tc('glossary.delete_term', 1)" )
    template( v-slot:body )
      .text-body-2.grey--text.text--darken-4 {{ $t('glossary.delete_terms_confirm') }}
    template( v-slot:actions )
      v-btn( class="custom-alert" color='alert' @click="deleteTerm" )
        v-icon(left) icon-trash
        | {{ $t('common.words.delete') }}
</template>
